import React from 'react'
import styled from 'styled-components'

const TinyText = styled.dt`
  font-size: 12px;
`

const Tag = ({ hex, children, classes, opacity }) => (
  <div>
    <div className={`black dib mb1 ${classes}`} style={{ background: hex, opacity: opacity }}>
      <TinyText className="p-3 geo-regular ph1 pv1 mt0 lh-solid">{children}</TinyText>
    </div>
  </div>
)

Tag.defaultProps = {
  classes: '',
}

export default Tag
