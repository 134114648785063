import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { lightenDarkenColor } from '../../helpers/colorTransforms'

const Wrapper = styled(Link)`
  @media (min-width: 30em) {
    min-width: 200px;
  }
  background-color: ${props =>
    props.disabled ? props.theme.primaryLightGrey : props.theme.primaryOrange};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  transition: all 0.10s ease-in;
  min-width: 90px;
  height: 35px;
  margin: auto;
  &:hover {
    background-color: ${props => lightenDarkenColor(props.theme.primaryOrange, 20)};
    text-decoration: none;
    color: #fff;
    text-decoration: none;
  }
`

const NextLink = ({ children, to, disabled, direction, ...rest }) => (
  <Wrapper
    disabled={disabled}
    to={to}
    {...rest}
    className="flex flex-auto items-center justify-center w-25 tc db geo-semibold f5 white hover:white hover-no-underline no-underline ph1 ph2-ns pv1 pointer input-reset ttu"
  >
    {direction === 'left' && (
      <img src={require('../BackLink/arrow-2.svg').default}  alt="Arrow Left" width="14" className="mr1"/> 
    )}
    {children}
    {direction === 'right' && (
      <img src={require('../Summary/arrow-3.svg').default} alt="Arrow Right" width="14" className="ml1"/>
    )}
  </Wrapper>
)

export default NextLink
