import React from 'react'

function Icon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
      <rect
        width="23"
        height="23"
        x="1"
        y="1"
        fill="#43B245"
        stroke="#43B245"
        strokeWidth="2"
        rx="11.5"
      ></rect>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.25"
        d="M19.216 8.658l.089-.089-.089-.088-1.071-1.07-.088-.087-.089.088-7.957 7.933-3.019-3.006-.088-.087-.088.088-1.071 1.069-.09.088.09.089 4.178 4.17.088.088.089-.089 9.116-9.097z"
      ></path>
    </svg>
  )
}

export default Icon
