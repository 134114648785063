import React from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import NextLink from '../../components/NextLink/NextLink'
import BackLink from '../../components/BackLink'

const Wrapper = styled.section`
  background: #0d282e;
  height: 66px;
  border-top: 1px solid #0d282e;
`
const Text = styled.span`
  color: #393939;
`
const Left = styled.div`
  width: 140px;

  @media (min-width: 30em) {
    width: 170px;
  }
`

const SelectionBar = ({
  openView,
  options: { boats, drivetrains, tops, seats, colorareas, equipments, addons },
  build,
  match,
  mobile,
  userCurrency,
  handleSelect,
}) => {
  if (mobile && !build) {
    return (
      <section className="dn-ns">
        <span className="dib geo-semibold white f4 lh-title truncate bp3-skeleton">
          Drivetrain 1/4
        </span>
      </section>
    )
  }

  if (!build) {
    return (
      <Wrapper className="dn db-ns fixed-ns w-100 bottom-0 bg-silver z-999">
        <div className="flex items-center mw8 center pv2 ph3 justify-between">
          <Text className="w-40 geo-semibold f5 bp3-skeleton">Drivetrain 1/4</Text>
          <div className="w4-ns">
            <NextLink to={`${match.url}/style`}>Next</NextLink>
          </div>
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper className="flex justify-between fixed w-100 bottom-0 bg-blue-grey z-999">
      <div className="flex items-center mh1 center justify-between w-100 white">
        <Route
          path={`${match.url}/style/drivetrains`}
          render={() => (
            <>
              <div className="w-30 w-10-ns flex h-100 items-center">
                <Left className="br border-dark h-100 flex items-center">
                  <Text className="flex-auto truncate geo-semibold p-1 ph4-ns ph3">
                    <span className="geo-semibold white">Drivetrain</span>
                  </Text>
                </Left>
                <div className="br border-dark h-100 flex-ns dn items-center">
                  <Text className="flex-auto truncate geo-semibold ph4">
                    <span className="geo-semibold blue-green p-1">Step 1/4</span>
                  </Text>
                </div>
              </div>
              {/* <div className="w-70 w-20-ns ph4-ns pv3 bl-ns border-dark h-100"> */}
              <div className="w-70 w-80-ns h-100">
                {/* <div className="flex mr4-ns pr4-ns"> */}
                <div className="flex items-center h-100 pl3 pl0-ns">
                  <div className="w-70-ns w-60 justify-center flex justify-end-ns br-ns border-dark ph4 pv3 h-100"></div>
                  {/* <div className="w-20-ns w-40 flex mr4-ns pr4-ns pl4-ns pv3 h-100"> */}
                  <div className="w-20-ns w-40 flex mr4-ns pr4-ns pl4-ns pv3 h-100 mr3">
                    <NextLink direction="right" to={`${match.url}/style/tops-and-seats`}>
                      Next
                    </NextLink>
                  </div>
                </div>
              </div>
            </>
          )}
        />
        <Route
          path={`${match.url}/style/tops-and-seats`}
          render={() => (
            <>
              <div className="w-30 w-10-ns flex h-100 items-center">
                <Left className="br border-dark h-100 flex items-center">
                  <Text className="flex-auto truncate geo-semibold p-1 ph4-ns ph3">
                    <span className="geo-semibold white">Tops & Seats</span>
                  </Text>
                </Left>
                <div className="dn br border-dark h-100 flex-ns items-center">
                  <Text className="flex-auto truncate geo-semibold ph4">
                    <span className="geo-semibold blue-green p-1">Step 2/4</span>
                  </Text>
                </div>
              </div>
              <div className="w-70 w-80-ns h-100">
                <div className="flex items-center h-100 pl3 pl0-ns">
                  <div className="w-70-ns w-60 justify-center flex justify-end-ns br-ns border-dark ph4 pv3 h-100">
                    <BackLink direction="left" to={`${match.url}/style/drivetrains`}>
                      Previous
                    </BackLink>
                  </div>
                  <div className="w-20-ns w-40 flex mr4-ns pr4-ns pl4-ns pv3 h-100 mr3">
                    <NextLink direction="right" to={`${match.url}/style/colors`}>
                      Next
                    </NextLink>
                  </div>
                </div>
              </div>
            </>
          )}
        />

        <Route
          path={`${match.url}/style/colors`}
          render={() => (
            <>
              <div className="w-30 w-10-ns flex h-100 items-center">
                <Left className="br border-dark h-100 flex items-center">
                  <Text className="flex-auto truncate geo-semibold p-1 ph4-ns ph3">
                    <span className="geo-semibold white">Style</span>
                  </Text>
                </Left>
                <div className="dn br border-dark h-100 flex-ns items-center">
                  <Text className="flex-auto truncate geo-semibold ph4">
                    <span className="geo-semibold blue-green p-1">Step 3/4</span>
                  </Text>
                </div>
              </div>
              <div className="w-70 w-80-ns h-100">
                <div className="flex items-center h-100 pl3 pl0-ns">
                  <div className="w-70-ns w-60 justify-center flex justify-end-ns br-ns border-dark ph4 pv3 h-100">
                    <BackLink direction="left" to={`${match.url}/style/tops-and-seats`}>
                      Previous
                    </BackLink>
                  </div>
                  <div className="w-20-ns w-40 flex mr4-ns pr4-ns pl4-ns pv3 h-100 mr3">
                    <NextLink direction="right" to={`${match.url}/style/equipment`}>
                      Next
                    </NextLink>
                  </div>
                </div>
              </div>
            </>
          )}
        />

        <Route
          path={`${match.url}/style/equipment`}
          render={() => (
            <>
              <div className="w-30 w-10-ns flex h-100 items-center">
                <Left className="br border-dark h-100 flex items-center">
                  <Text className="flex-auto truncate geo-semibold p-1 ph4-ns ph2">
                    <span className="geo-semibold white">Equipment</span>
                  </Text>
                </Left>
                <div className="dn br border-dark h-100 flex-ns items-center">
                  <Text className="flex-auto truncate geo-semibold p-1 ph4">
                    <span className="geo-semibold blue-green">Step 4/4</span>
                  </Text>
                </div>
              </div>
              <div className="w-70 w-80-ns h-100">
                <div className="flex items-center h-100 pl3 pl0-ns">
                  <div className="w-70-ns w-60 justify-center flex justify-end-ns br-ns border-dark ph4 pv3 h-100">
                    <BackLink direction="left" to={`${match.url}/style/colors`}>
                      Previous
                    </BackLink>
                  </div>
                  <div className="w-20-ns w-40 flex mr4-ns pr4-ns pl4-ns pv3 h-100 mr3">
                    <NextLink direction="right" to={`${match.url}/summary`}>
                      Next
                    </NextLink>
                  </div>
                </div>
              </div>
            </>
          )}
        />

        {/* <Route
          path={`${match.url}/options`}
          render={() => (
            <>
              <Text className="flex-auto w-60 truncate geo-semibold f5">
                <span className="geo-semibold">Options</span> 5/5
              </Text>
              <div className="w5">
                <div className="flex">
                  <BackLink direction="left" to={`${match.url}/style/equipment`}>
                    Previous
                  </BackLink>
                  <NextLink direction="right" to={`${match.url}/summary`}>
                    Next
                  </NextLink>
                </div>
              </div>
            </>
          )}
        /> */}
      </div>
    </Wrapper>
  )
}

export default SelectionBar
