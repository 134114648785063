import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="28" height="28" viewBox="0 0 31 32">
      <path
        fill="#fff"
        d="M29.886 14.063c-.048-.485-.145-.97-.242-1.454H1.356c-.096.485-.193.97-.242 1.454h28.772zM1.114 17.936c.049.485.146.97.242 1.454h28.24c.096-.485.193-.97.242-1.453H1.114z"
      ></path>
      <path
        fill="#fff"
        d="M9.688 2.68c-.485.193-.97.484-1.454.726v25.188c.485.29.97.533 1.453.726V2.68zm3.874-1.066v28.82l1.454.146V1.469c-.485 0-.97.048-1.454.145z"
      ></path>
      <path
        fill="#2E6AC4"
        d="M.969 16c0 .678.048 1.308.145 1.938h28.82c.097-.63.146-1.26.146-1.938 0-.678-.049-1.308-.146-1.938H1.114C1.017 14.693.97 15.322.97 16z"
      ></path>
      <path
        fill="#2E6AC4"
        d="M9.688 29.32c1.21.533 2.518.92 3.874 1.066V1.614c-1.356.194-2.664.533-3.874 1.066v26.64z"
      ></path>
      <path
        fill="#CD0014"
        d="M8.235 12.61V3.405a14.776 14.776 0 00-6.879 9.203h6.879zm0 6.78v9.204a14.776 14.776 0 01-6.879-9.203h6.879zM15.5 1.47h-.484v11.14h14.628C28.094 6.216 22.378 1.47 15.5 1.47zm-.484 17.922v11.14h.484c6.878 0 12.594-4.747 14.144-11.14H15.016z"
      ></path>
    </svg>
  )
}

export default Icon
