import { createActions } from 'redux-actions'
import firebase from 'firebase/app'
import 'firebase/database'
import { getBrowserLanguage } from '../../helpers/getBrowserLanguage'

export const {
  createBuildRequest,
  createBuildSuccess,
  createBuildFailure,
  fetchBuildRequest,
  fetchBuildSuccess,
  fetchBuildFailure,
  updateBuildRequest,
  updateBuildSuccess,
  updateBuildFailure,
  syncBuildSuccess,
} = createActions(
  'CREATE_BUILD_REQUEST',
  'CREATE_BUILD_SUCCESS',
  'CREATE_BUILD_FAILURE',
  'FETCH_BUILD_REQUEST',
  'FETCH_BUILD_SUCCESS',
  'FETCH_BUILD_FAILURE',
  'UPDATE_BUILD_REQUEST',
  'UPDATE_BUILD_SUCCESS',
  'UPDATE_BUILD_FAILURE',
  'SYNC_BUILD_SUCCESS'
)

// Creates a new order in Firebase
export const createBuild = (id, boatSlug, values, options) => {
  let seat = options?.seats?.result[0]
  let top = options?.tops?.result[0]
  let drivetrain = options?.drivetrains?.result[0]
  let firstColorArea = options?.colorareas?.result[0]
  let secondColorArea = options?.colorareas?.result[1]

  let colorEntities = options?.colorareas?.entities
  const turnColorArray = Object.values(colorEntities)

  let getColors = turnColorArray.map((item) => {
    let firstColor = null
    let secondColor = null
    if (item.id === firstColorArea) {
      firstColor = item.acf?.colors[0]?.color.term_id
    }
    if (item.id === secondColorArea) {
      secondColor = item.acf?.colors[0]?.color.term_id
    }
    
    return {
      firstColor,
      secondColor
    }
  })

  let removeNull = getColors.map((item) => {
    if(item.firstColor || item.secondColor !== null)
    return item
  })

  removeNull = removeNull.filter(( item ) => {
    return item !== undefined;
  });

  let colors = {
    [firstColorArea]: removeNull[0].firstColor,
    [secondColorArea]: removeNull[1].secondColor,
  }

  options?.colorareas?.result.map((areaId) => {
    colors = {
      ...colors,
      [areaId]: options?.colorareas?.entities[areaId]?.acf?.colors[0]?.color?.term_id,
    }
  })

  const updates = {
    [`/builds/${id}`]: {
      id,
      boatSlug,
      seat,
      top,
      colors,
      drivetrain,
      step: 'drivetrain',
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      status: 'Request',
      currency: getBrowserLanguage(),
      ...values,
    },
  }
  return {
    types: [createBuildRequest().type, createBuildSuccess().type, createBuildFailure().type],
    promise: firebase.database().ref().update(updates),
  }
}

// Sync build with Firebase for Realtime Goodness™
export const syncBuild = (id) => (dispatch, getState) => {
  return firebase
    .database()
    .ref(`/builds/${id}`)
    .on('value', (snap) => {
      dispatch(syncBuildSuccess(snap.val()))
    })
}

// Fetch one build by id from Firebase
export const fetchBuild = (id) => {
  return {
    types: [fetchBuildRequest().type, fetchBuildSuccess().type, fetchBuildFailure().type],
    promise: firebase.database().ref(`/builds/${id}`).once('value'),
  }
}

// Save a build in Firebase
const saveBuild = (updates) => {
  return {
    types: [updateBuildRequest().type, updateBuildSuccess().type, updateBuildFailure().type],
    promise: firebase.database().ref().update(updates),
  }
}

// Save specific values on a build in Firebase
export const updateBuild =
  (values, type = null, id) =>
  (dispatch, getState) => {
    let updates = {
      [`/builds/${id}/updatedAt`]: Date.now(),
    }
    // eslint-disable-next-line
    Object.keys(values).map((key) => {
      if (type) {
        updates[`/builds/${id}/${type}/${key}`] = values[key]
      } else {
        updates[`/builds/${id}/${key}`] = values[key]
      }
    })
    return dispatch(saveBuild(updates))
  }
