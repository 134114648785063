import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FieldArray, Field, reduxForm, formValueSelector } from 'redux-form'
import { Button, Intent, Dialog, Popover, Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { Link, Redirect } from 'react-router-dom'
import Helmet from 'react-helmet'
import styled from 'styled-components'
// Redux store
import { getOptions, getOptionsIsFetching } from '../../store/Options/selectors'
import { fetchOptions } from '../../store/Options/actions'
import {
  loadOrder,
  saveOrder,
  syncOrder,
  deleteOrder,
  sendToMyGoldfish,
} from '../../store/Orders/actions'
import { getOrder, getIsFetching, getIsSaving } from '../../store/Orders/selectors'
import { updateSelection } from '../../store/Selection/actions'
import { getSelection } from '../../store/Selection/selectors'
import { loadAllUsers } from '../../store/Users/actions'
import { getAllUsers } from '../../store/Users/selectors'
import { getUser } from '../../store/User/selectors'
// Helpers & constants
// import * as validator from '../../helpers/validators'
// Components
import Navbar from '../../components/Navbar/Navbar'
import Loading from '../../components/Loading/Loading'
import Section from '../../components/Section/Section'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import CustomLineItem from '../../components/CustomLineItem/CustomLineItem'
import EquipmentLineItem from '../../components/EquipmentLineItem/EquipmentLineItem'
import Panel from '../../components/Panel/Panel'
import ModelSelector from '../../components/ModelSelector/ModelSelector'
import CustomerInformation from '../../components/CustomerInformation/CustomerInformation'
import ConfigurationLineItem from '../../components/ConfigurationLineItem/ConfigurationLineItem'
import ColorLineItem from '../../components/ColorLineItem/ColorLineItem'
import Date from '../../components/Date/Date'
import Textarea from '../../components/Textarea/Textarea'
import Select from '../../components/Select/Select'
import SelectCountry from '../../components/Select/SelectCountry'
import Input from '../../components/Input/Input'
import InputTextHolder from '../../components/InputTextHolder/InputTextHolder'
import SelectWithDesc from '../../components/SelectWithDesc/SelectWithDesc'
// import BoatImage from '../../components/BoatImage/BoatImage'
import BoatImagePoc from '../../components/BoatImage/BoatImagePoc'
import { config } from '../../config'

const Wrapper = styled.div`
  background-color: #f5f8fa;
  min-height: 100vh;
`

class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedBoat: null,
      isOpen: false,
      shouldRedirectTo: null,
      exportingToWp: false,
    }
    this.handleModelChange = this.handleModelChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleDialog = this.toggleDialog.bind(this)
    this.deleteOrder = this.deleteOrder.bind(this)
    this.handleSendToMyGoldfish = this.handleSendToMyGoldfish.bind(this)
    this.donwloadImage = this.donwloadImage.bind(this)
    this.handleExportToWp = this.handleExportToWp.bind(this)
    this.handleExportImageToWp = this.handleExportImageToWp.bind(this)
  }

  componentDidMount() {
    this.props.syncOrder(this.props.match.params.id)
    this.props
      .loadOrder(this.props.match.params.id)
      .then((response) => this.props.fetchOptions())
      .then((response) => this.props.updateSelection(this.props.order.boatmodel))
      .then((response) =>
        this.setState({
          boatmodel: this.props.order.boatmodel,
          selectedBoat: `${this.props.order.boat}`,
          boatSlug: this.props.options.boats.entities[this.props.order.boat]?.slug || '23-tender',
        })
      )
    this.props.loadAllUsers()
  }

  handleModelChange(e) {
    const boatmodel = this.props.options.boats.entities[e.target.value].boatmodel[0]
    this.props.updateSelection(boatmodel)
    // this.props.reset()
    this.setState({
      selectedBoat: e.target.value,
      boatmodel: boatmodel,
      boatSlug: this.props.options.boats.entities[e.target.value].slug,
    })
  }

  onSubmit(values) {
    this.props.saveOrder(
      {
        ...this.props.order,
        ...values,
        boat: this.state.selectedBoat,
        boatmodel: this.state.boatmodel,
        boatSlug: this.state.boatSlug,
      },
      this.props.match.params.id
    )
  }

  toggleDialog() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  deleteOrder() {
    this.props
      .deleteOrder(this.props.match.params.id)
      .then((response) => this.setState({ shouldRedirectTo: '/' }))
  }

  handleSendToMyGoldfish() {
    this.props.sendToMyGoldfish(this.props.order)
  }

  handleExportToWp = () => {
    this.setState({ exportingToWp: true })

    const canvas = document.getElementsByTagName('canvas')[0]
    const link = document.createElement('a')

    link.setAttribute('download', `build-${this.props.order.id}.png`) // Set filename
    link.setAttribute(
      'href',
      canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    )

    const base64 = link.href

    const id = this.props.order.id

    fetch('https://goldfish-webhok-handler.vercel.app/api/wp-update/updateFromSales/', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ key: 'rye3rfa1aqh@WKW2xdz', orderId: id, base64: base64 }),
    })
      .then((response) => {
        setTimeout(() => {
          this.setState({ exportingToWp: false })
        }, 1000)
      })
      .catch((error) => console.error(error))
  }

  handleExportImageToWp = () => {
    this.setState({ exportingToWp: true })

    const canvas = document.getElementsByTagName('canvas')[0]
    const link = document.createElement('a')

    link.setAttribute('download', `build-${this.props.order.id}.png`) // Set filename
    link.setAttribute(
      'href',
      canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    )

    const base64 = link.href

    const id = this.props.order.id

    fetch('https://my.goldfishboat.com/wp-json/codehouse/v1/uploadimagefromsales', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId: id,
        base64: base64,
        title: `build-${this.props.order.id}`,
      }),
    })
      .then((response) => {
        setTimeout(() => {
          this.setState({ exportingToWp: false })
        }, 1000)
      })
      .catch((error) => console.error(error))
  }

  donwloadImage() {
    const canvas = document.getElementsByTagName('canvas')[0]
    const link = document.createElement('a')
    //  link.download = 'boat.png'
    //  link.href = canvas.toDataURL()
    //  link.click()
    // let c = document.createElement('canvas')
    // c.width = 1500
    // c.height = 800
    // let ctx = c.getContext('2d')
    // for (let i = 0; i < document.images.length - 1; i++) {
    //   ctx.drawImage(document.images[i], 0, 0)
    // }
    // let link = document.createElement('a')
    link.setAttribute('download', `build-${this.props.order.id}.png`) // Set filename
    link.setAttribute(
      'href',
      canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    )
    link.click() // Simulate click to download
  }

  render() {
    const {
      match,
      order,
      isSaving,
      isFetching,
      isOptionsFetching,
      selection,
      options,
      currentSelectedOptions,
      handleSubmit,
      invalid,
      submitFailed,
      anyTouched,
      pristine,
      submitting,
      users,
      user,
    } = this.props

    const { selectedBoat, shouldRedirectTo } = this.state
    const boat = options?.boats ? options?.boats?.entities[order?.boat] : false

    if (shouldRedirectTo !== null) {
      return <Redirect to={shouldRedirectTo} />
    }
    // {
    //   console.log(order)
    // }

    if (isFetching || isOptionsFetching || !options.drivetrains) {
      return (
        <Wrapper>
          <Navbar />
          <Section>
            <Helmet title={'Loading...'} />
            <div className="flex justify-between items-center">
              <Breadcrumb skeleton id="r1pfenzie" />
              <div>
                <span className="mr3 bp3-skeleton">Updated: 10 minutes ago</span>
                <Popover
                  useSmartPositioning
                  content={
                    <Menu>
                      <MenuItem
                        icon="export"
                        onClick={this.handleSendToMyGoldfish}
                        text="Send to My Goldfish"
                      />
                      <MenuDivider />
                      <MenuItem
                        icon="delete"
                        text="Delete"
                        onClick={this.toggleDialog}
                        intent={Intent.DANGER}
                      />
                    </Menu>
                  }
                >
                  <Button icon="more" disabled className="mr2" />
                </Popover>
                <Button icon="upload" intent={Intent.DEFAULT} className="mr2">
                  Export image
                </Button>
                <Button icon="upload" intent={Intent.DEFAULT} className="mr2">
                  Export to WP
                </Button>
                <Link to="" target="_blank">
                  <Button icon="eye-open" intent={Intent.DEFAULT} className="mr2" disabled>
                    View
                  </Button>
                </Link>
                <Button type="submit" icon="floppy-disk" intent={Intent.SUCCESS} disabled>
                  Save
                </Button>
              </div>
            </div>
            <hr />
            <Panel title="Customer information">
              <Loading />
            </Panel>
            <Panel title="Model">
              <Loading />
            </Panel>
            <Panel title="Configuration">
              <Loading />
            </Panel>
          </Section>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <Dialog
          icon="trash"
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog}
          title="Confirm Delete"
        >
          <div className="bp3-dialog-body">
            Are you sure you want to delete order <span className="b">{match.params.id}</span>? This
            cannot be undone.
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Cancel" onClick={this.toggleDialog} />
              <Button intent={Intent.DANGER} onClick={this.deleteOrder} text="Delete" />
            </div>
          </div>
        </Dialog>
        <Navbar />
        <Section>
          <Helmet title={`Edit '${match.params.id}'`} />
          <div className="flex justify-between items-center">
            <Breadcrumb id={match.params.id} />
            <div>
              <span className="mr3">
                Updated: <Date data={order && order.updatedAt} />
              </span>
              <Popover
                useSmartPositioning
                content={
                  <Menu>
                    <MenuItem
                      icon="export"
                      onClick={this.handleSendToMyGoldfish}
                      text="Send to My Goldfish"
                      disabled={
                        user.uid &&
                        users.entities[user.uid] &&
                        users.entities[user.uid].role === 'Agent'
                      }
                    />
                    <MenuItem
                      icon="download"
                      onClick={this.donwloadImage}
                      text="Download Image"
                      disabled={
                        user.uid &&
                        users.entities[user.uid] &&
                        users.entities[user.uid].role === 'Agent'
                      }
                    />
                    <MenuDivider />
                    <MenuItem
                      icon="delete"
                      text="Delete"
                      onClick={this.toggleDialog}
                      intent={Intent.DANGER}
                    />
                  </Menu>
                }
              >
                <Button icon="more" className="mr2" />
              </Popover>
              <Button
                icon="upload"
                intent={Intent.DEFAULT}
                className="mr2"
                onClick={() => this.handleExportImageToWp()}
                disabled={this.state.exportingToWp === true}
              >
                Export image
              </Button>
              <Button
                icon="upload"
                intent={Intent.DEFAULT}
                className="mr2"
                onClick={() => this.handleExportToWp()}
                disabled={this.state.exportingToWp === true}
              >
                Export to WP
              </Button>
              <Link to={`/orders/${match.params.id}/view/`} target="_blank">
                <Button icon="eye-open" intent={Intent.DEFAULT} className="mr2">
                  View
                </Button>
              </Link>
              <Button
                type="submit"
                icon="floppy-disk"
                intent={Intent.SUCCESS}
                disabled={pristine || submitting || isSaving}
                loading={submitting || isSaving}
                onClick={handleSubmit(this.onSubmit)}
              >
                {!isSaving ? 'Save' : 'Saving...'}
              </Button>
              {submitFailed && invalid && anyTouched && (
                <p className="tr pt2">One or more fields are invalid. Please check your form.</p>
              )}
            </div>
          </div>
          <hr />
          <form className={isSaving && 'o-50'} onSubmit={handleSubmit(this.onSubmit)}>
            <CustomerInformation />
            <Panel title="Model">
              <ModelSelector
                onChange={this.handleModelChange}
                // eslint-disable-next-line
                selectedValue={selectedBoat || (order && `${order.boat}`)}
                options={options.boats}
              />
            </Panel>
            <Panel title="Configuration">
              <ConfigurationLineItem
                options={selection.drivetrains}
                currentSelectedOption={
                  currentSelectedOptions.drivetrain &&
                  options.drivetrains.entities[currentSelectedOptions.drivetrain].acf.price
                }
                name="drivetrain"
              />
              <ConfigurationLineItem
                options={selection.seats}
                currentSelectedOption={
                  currentSelectedOptions.seat &&
                  options.seats.entities[currentSelectedOptions.seat].acf.price
                }
                name="seat"
              />
              <ConfigurationLineItem
                options={selection.tops}
                currentSelectedOption={
                  currentSelectedOptions.top &&
                  options.tops.entities[currentSelectedOptions.top].acf.price
                }
                name="top"
              />
              <div className="flex flex-wrap">
                {selection.colorareas &&
                  selection.colorareas.map((color, index) => {
                    return (
                      <ColorLineItem
                        key={index}
                        options={color.colors}
                        name={`colors.${color.id}`}
                        label={color.displayName}
                        currentSelectedOption={
                          currentSelectedOptions.colors && currentSelectedOptions.colors[color.id]
                        }
                      />
                    )
                  })}
              </div>
            </Panel>
            <Panel title="Equipments">
              <FieldArray
                name="equipmentLineItems"
                component={EquipmentLineItem}
                type="equipment"
                options={selection.equipments}
                currentSelectedOption={options.equipments && options.equipments.entities}
                onSortEnd={this.onSortEnd}
              />
            </Panel>
            <Panel title="Addons">
              <FieldArray
                name="addonLineItems"
                component={EquipmentLineItem}
                type="addon"
                options={selection.addons}
                currentSelectedOption={options.addons && options.addons.entities}
                onSortEnd={this.onSortEnd}
              />
            </Panel>
            <Panel title="Custom Equipments">
              <FieldArray
                name="customLineItems"
                component={CustomLineItem}
                currency={order.currency}
              />
            </Panel>
            <Panel title="Special offers">
              <FieldArray
                name="specialOffersLineItems"
                component={CustomLineItem}
                currency={order.currency}
              />
            </Panel>

            <Panel title="Extra Information">
              <div className="flex flex-wrap">
                <Field
                  name="status"
                  component={SelectWithDesc}
                  description={[
                    'Offer: Status used for offers to customers, with the option to complete the order.',
                    'Request: Status used for customers requesting information.',
                    'Pending Payment: Status used when payment is inititated, but not completed.',
                    'Deposit Paid: Status used when a payment is confirmed.',
                  ]}
                  label="Status"
                  placeholder="Add order status"
                  type="select"
                  options={['Offer', 'Request', 'Pending Payment', 'Deposit Paid']}
                  classes="flex-auto mr4"
                  validate={[]}
                  autocomplete="status"
                  disabled={
                    user.uid &&
                    users.entities[user.uid] &&
                    users.entities[user.uid].role === 'Agent'
                  }
                />
                <Field
                  name="agent"
                  component={Select}
                  label="Agent"
                  placeholder="Assign to Agent"
                  type="select"
                  options={users && users.result.map((uid) => users.entities[uid])}
                  classes="flex-auto"
                  validate={[]}
                  autocomplete="status"
                  disabled={
                    user.uid &&
                    users.entities[user.uid] &&
                    users.entities[user.uid].role === 'Agent'
                  }
                />
              </div>
              <div className="flex flex-wrap">
                <Field
                  name="buildNumber"
                  component={Input}
                  label="Build Number"
                  placeholder="Add build number"
                  type="text"
                  validate={[]}
                  classes="flex-auto mr4-ns"
                />
                <Field
                  name="estimatedCompletion"
                  component={Input}
                  label="Estimated Completion"
                  placeholder="Add estimated completion date"
                  type="text"
                  validate={[]}
                  classes="flex-auto mr4-ns"
                />
                <Field
                  name="priceSummaryText"
                  component={InputTextHolder}
                  label="Price Summary Text"
                  placeholder="Add price summary text"
                  type="text"
                  validate={[]}
                  classes="flex-auto mr4-ns"
                  inputText="Build Price"
                />
                <Field
                  name="totalCostSummaryText"
                  component={InputTextHolder}
                  label="Total Cost Summary Text"
                  placeholder="Add total cost summary text"
                  type="text"
                  validate={[]}
                  classes="flex-auto"
                  inputText="Sum"
                />
              </div>
              <Field
                name="terms"
                component={Textarea}
                label="Terms and conditions"
                placeholder={config.content.defaultTerms}
                type="textarea"
                validate={[]}
                autocomplete="terms"
                classes="flex-auto"
                rows="6"
                disabled={
                  user.uid && users.entities[user.uid] && users.entities[user.uid].role === 'Agent'
                }
              />
            </Panel>
          </form>
          <div className="" style={{ visibility: 'hidden' }}>
            <div style={{ height: '600px' }}>
              {order && order.drivetrain && order.seat && order.top && (
                <BoatImagePoc
                  options={options}
                  build={order}
                  boat={boat}
                  visibleScroller={this.state.visibleScroller}
                />
              )}
              {/* {order && order.drivetrain && <BoatImage options={options} build={order} />} */}
            </div>
          </div>
        </Section>
      </Wrapper>
    )
  }
}

const selector = formValueSelector('edit')
const mapStateToProps = (state, ownProps) => ({
  options: getOptions(state),
  order: getOrder(state, ownProps.match.params.id),
  isSaving: getIsSaving(state),
  isFetching: getIsFetching(state),
  isOptionsFetching: getOptionsIsFetching(state),
  initialValues: getOrder(state, ownProps.match.params.id),
  selection: getSelection(state),
  users: getAllUsers(state),
  user: getUser(state),
  currentSelectedOptions: selector(
    state,
    'drivetrain',
    'top',
    'seat',
    'colors',
    'equpimentLineItems',
    'addonsLineItems'
  ),
})

// eslint-disable-next-line
Edit = reduxForm({
  form: 'edit',
  enableReinitialize: true, // Force updating of inital values after fetch is finished
  keepDirtyOnReinitialize: true, // Don't overwrite edited fields after initialize
})(Edit)

export default connect(mapStateToProps, {
  loadOrder,
  loadAllUsers,
  saveOrder,
  sendToMyGoldfish,
  syncOrder,
  deleteOrder,
  fetchOptions,
  updateSelection,
})(Edit)
