import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import Helmet from 'react-helmet'
import shortid from 'shortid'
import { Button, Intent, Dialog } from '@blueprintjs/core'
import { Table, Thead, Th, Tr, Td } from 'reactable'
import styled from 'styled-components'
// Redux store
import { loadAllOrders, createOrder, deleteOrder, syncOrders } from '../../store/Orders/actions'
import { getOrders, getIsFetching } from '../../store/Orders/selectors'
import { fetchOptionByType } from '../../store/Options/actions'
import { getBoats } from '../../store/Options/selectors'
import { loadAllUsers } from '../../store/Users/actions'
import { getUser } from '../../store/User/selectors'
import { getAllUsers, getIsUserFetching } from '../../store/Users/selectors'
// Components
import Navbar from '../../components/Navbar/Navbar'
import Section from '../../components/Section/Section'
import Date from '../../components/Date/Date'
import Loading from '../../components/Loading/Loading'
import Panel from '../../components/Panel/Panel'

const Wrapper = styled.div`
  background-color: #f5f8fa;
  height: 100vh;
`

class Orders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldRedirectTo: null,
      isOpen: false,
      currentlySelectedOrder: null,
    }
    this.handleCreateOrder = this.handleCreateOrder.bind(this)
    this.handleFilterTable = this.handleFilterTable.bind(this)
    this.deleteOrder = this.deleteOrder.bind(this)
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  static propTypes = {
    orders: PropTypes.object,
  }

  componentDidMount() {
    this.props.loadAllUsers().then(response => this.props.loadAllOrders())
    this.props.fetchOptionByType('boats')
  }

  handleCreateOrder() {
    const id = shortid.generate()
    let agent = null
    if (
      this.props.user.uid &&
      this.props.users.entities[this.props.user.uid] &&
      this.props.users.entities[this.props.user.uid].role === 'Agent'
    ) {
      agent = this.props.user.uid
    }
    this.props.createOrder(id, { agent }).then(response => this.setState({ shouldRedirectTo: id }))
  }

  handleFilterTable(e) {
    this.setState({ filterValue: e.target.value })
  }

  toggleDialog(e) {
    this.setState({
      isOpen: !this.state.isOpen,
      currentlySelectedOrder: e.target.name,
    })
  }

  deleteOrder() {
    this.props
      .deleteOrder(this.state.currentlySelectedOrder)
      .then(response => this.setState({ isOpen: false, currentlySelectedOrder: null }))
      .then(response => this.props.syncOrders())
  }

  render() {
    const {
      orders,
      isFetching,
      isUserFetching,
      boats,
      users,
      location: { pathname },
    } = this.props

    const { shouldRedirectTo } = this.state

    if (isFetching || isUserFetching) {
      return (
        <Wrapper>
          <Navbar />
          <Section>
            <Helmet title="Orders" />
            <div className="flex justify-between">
              <Button icon="add" disabled intent={Intent.PRIMARY}>
                New Order
              </Button>
              <Loading />
            </div>
            <Panel title="Orders">
              <div className="bp3-input-group bp3-large mb2">
                <span className="bp3-icon bp3-icon-search" />
                <input
                  disabled
                  className="bp3-input"
                  type="search"
                  placeholder="Search for orders, boats, customers, agents ..."
                  dir="auto"
                />
              </div>
              <Table
                className="w-100 bp3-html-table bp3-html-table-interactive bp3-html-table-striped"
                sortable
                filterable={['id', 'customer', 'boat', 'agent', 'status']}
                defaultSort={{ column: 'updatedAt', direction: 'desc' }}
                hideFilterInput
                filterBy={this.state.filterValue}
              >
                <Thead>
                  <Th className="pointer" column="id">
                    Order id
                    <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                  </Th>
                  <Th className="pointer" column="customer">
                    Customer
                    <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                  </Th>
                  <Th className="pointer" column="boat">
                    Boat
                    <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                  </Th>
                  <Th className="pointer" column="agent">
                    Agent
                    <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                  </Th>
                  <Th className="pointer" column="status">
                    Status
                    <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                  </Th>
                  <Th className="pointer" column="createdAt">
                    Created At
                    <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                  </Th>
                  <Th className="pointer" column="updatedAt">
                    Updated At
                    <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                  </Th>
                  <Th className="pointer" column="tools">
                    Tools
                  </Th>
                </Thead>
                <Tr>
                  <Td column="id">
                    <span className="bp3-skeleton">12387ewhf</span>
                  </Td>
                  <Td column="customer">
                    <span className="bp3-skeleton">Henrik Sollie</span>
                  </Td>
                  <Td column="boat">
                    <span className="bp3-skeleton">38 supersport</span>
                  </Td>
                  <Td column="agent">
                    <span className="bp3-skeleton">Jørgen Lowe</span>
                  </Td>
                  <Td column="status">
                    <span className="bp3-skeleton">Offer</span>
                  </Td>
                  <Td column="createdAt">
                    <span className="bp3-skeleton">Mar 06, 2017</span>
                  </Td>
                  <Td column="updatedAt">
                    <span className="bp3-skeleton">Mar 06, 2017</span>
                  </Td>
                  <Td column="tools" style={{ padding: '5px 11px' }}>
                    <div className="bp3-button-group bp3-minimal o-20">
                      <Link
                        to="#"
                        className="bp3-button bp3-icon-eye-open"
                        tabIndex="0"
                        role="button"
                        target="_blank"
                      />
                      <a
                        href="/"
                        className="bp3-button bp3-icon-trash bp3-intent-warning"
                        tabIndex="0"
                        role="button"
                      >
                        Link
                      </a>
                    </div>
                  </Td>
                </Tr>
              </Table>
            </Panel>
          </Section>
        </Wrapper>
      )
    }

    if (shouldRedirectTo !== null) {
      return <Redirect to={`${pathname}orders/${shouldRedirectTo}/edit/`} />
    }

    return (
      <Wrapper>
        <Navbar />
        <Dialog
          icon="trash"
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog}
          title="Confirm Delete"
        >
          <div className="bp3-dialog-body">
            Are you sure you want to delete order{' '}
            <span className="b">{this.state.currentlySelectedOrder}</span>? This cannot be undone.
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Cancel" onClick={this.toggleDialog} />
              <Button intent={Intent.DANGER} onClick={this.deleteOrder} text="Delete" />
            </div>
          </div>
        </Dialog>
        <Section>
          <Helmet title="Orders" />
          <Button icon="add" onClick={this.handleCreateOrder} intent={Intent.PRIMARY}>
            New Order
          </Button>
          <Panel title="Orders">
            <div className="bp3-input-group bp3-large mb2">
              <span className="bp3-icon bp3-icon-search" />
              <input
                onChange={this.handleFilterTable}
                className="bp3-input"
                type="search"
                placeholder="Search for orders, boats, customers, agents ..."
                dir="auto"
              />
            </div>
            <Table
              className="w-100 bp3-html-table bp3-html-table-interactive bp3-html-table-striped"
              sortable
              filterable={['id', 'customer', 'boat', 'agent', 'status']}
              defaultSort={{ column: 'updatedAt', direction: 'desc' }}
              hideFilterInput
              filterBy={this.state.filterValue}
            >
              <Thead>
                <Th className="pointer" column="id">
                  Order id
                  <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                </Th>
                <Th className="pointer" column="customer">
                  Customer
                  <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                </Th>
                <Th className="pointer" column="boat">
                  Boat
                  <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                </Th>
                <Th className="pointer" column="agent">
                  Agent
                  <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                </Th>
                <Th className="pointer" column="status">
                  Status
                  <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                </Th>
                <Th className="pointer" column="createdAt">
                  Created At
                  <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                </Th>
                <Th className="pointer" column="updatedAt">
                  Updated At
                  <span className="bp3-icon-standard bp3-icon-double-caret-vertical" />
                </Th>
                <Th className="pointer" column="tools">
                  Tools
                </Th>
              </Thead>
              {Object.keys(orders)
                .sort((a, b) => orders[a].createdAt - orders[b].createdAt)
                .reverse()
                .map(key => {
                  if (key === '_error' || key === '_isFetching' || key === '_isSaving') {
                    return null
                  } else {
                    return (
                      <Tr key={key}>
                        <Td column="id">
                          <Link to={`${pathname}orders/${key}/edit/`}>{key}</Link>
                        </Td>
                        <Td column="customer">{`${orders[key].firstName &&
                          orders[key].firstName} ${orders[key].lastName &&
                          orders[key].lastName}`}</Td>
                        <Td column="boat">
                          {(boats &&
                            orders[key].boat &&
                            boats.entities[orders[key].boat]?.title?.rendered) ||
                            'N/A'}
                        </Td>
                        <Td column="agent">
                          {users && orders[key].agent && users.entities[orders[key].agent]
                            ? users.entities[orders[key].agent].displayName
                            : 'No agent'}
                        </Td>
                        {/* <Td column='agent'>{orders[key].agent && orders[key].agent}</Td> */}
                        <Td column="status">{orders[key].status && orders[key].status}</Td>
                        <Td column="createdAt" value={orders[key].createdAt}>
                          {orders[key].createdAt && <Date data={orders[key].createdAt} />}
                        </Td>
                        <Td column="updatedAt" value={orders[key].updatedAt}>
                          {orders[key].updatedAt && <Date data={orders[key].updatedAt} />}
                        </Td>
                        <Td column="tools" style={{ padding: '5px 11px' }}>
                          <div className="bp3-button-group bp3-minimal">
                            <Link
                              to={`${pathname}orders/${key}/view/`}
                              className="bp3-button bp3-icon-eye-open"
                              tabIndex="0"
                              role="button"
                              target="_blank"
                            />
                            <button
                              name={key}
                              onClick={this.toggleDialog}
                              className="bp3-button bp3-icon-trash"
                            />
                          </div>
                        </Td>
                      </Tr>
                    )
                  }
                })}
            </Table>
          </Panel>
        </Section>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => ({
  orders: getOrders(state),
  isFetching: getIsFetching(state),
  boats: getBoats(state),
  users: getAllUsers(state),
  user: getUser(state),
  isUserFetching: getIsUserFetching(state),
})

export default connect(mapStateToProps, {
  loadAllOrders,
  loadAllUsers,
  createOrder,
  fetchOptionByType,
  deleteOrder,
  syncOrders,
})(Orders)
