import React from 'react'
import TotalBuildPrice from '../../components/TotalBuildPrice/TotalBuildPrice'
import getCurrentTotalCost from '../../helpers/getCurrentTotalCost'
import { Dialog } from '@reach/dialog'
import CloseIcon from './CloseIcon'
import CheckMarkIcon from './CheckMarkIcon'
import EurFlagIcon from './EurFlagIcon'
import NokFlagIcon from './NokFlagIcon'

const CurrencySelector = (props) => {
  const {
    drivetrains,
    tops,
    seats,
    colorareas,
    equipments,
    addons,
    build,
    userCurrency,
    handleSelect,
  } = props

  // console.log(build)

  const [showDialog, setShowDialog] = React.useState(false)
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  return (
    <div>
      <button
        className="p-1 input-reset bt-0 bl-0 br-0 ph0 pointer geo-semibold bb border-green dib bt-0 br-0 bl-0"
        onClick={open}
      >
        <span className="geo-semibold f5">{userCurrency}</span>{' '}
        <TotalBuildPrice
          className="geo-semibold f5 pointer-events-none"
          from={
            getCurrentTotalCost(
              drivetrains,
              tops,
              seats,
              colorareas,
              equipments,
              build,
              userCurrency,
              addons
            ) - 20000
          }
          to={getCurrentTotalCost(
            drivetrains,
            tops,
            seats,
            colorareas,
            equipments,
            build,
            userCurrency,
            addons
          )}
        />
        <span className="f6 geo-semibold">{userCurrency === 'EUR' ? 'ex' : 'inc'} VAT</span>
      </button>
      <Dialog
        className="pa3"
        style={{ width: '360px', zIndex: 9999 }}
        isOpen={showDialog}
        onDismiss={close}
        aria-label="Select currency"
      >
        <div className="relative">
          <h3 className="geo-semibold f4 mt0 mb3">Show prices in</h3>
          <button
            className="input-reset pointer absolute top-0 right-0 b--none nt3 nr3 pa0 bg-transparent"
            style={{ width: '20px', height: '20px' }}
            onClick={close}
          >
            <CloseIcon></CloseIcon>
          </button>
          <div class="w-100 bb b--light-gray"></div>
          <button
            className={`w-100 pointer b--none flex items-center pv2 justify-between f5 geo-semibold ${
              userCurrency === 'EUR' ? 'bg-near-white' : 'bg-white'
            }`}
            onClick={() => {
              handleSelect({ currentTarget: { dataset: { name: 'currency', value: 'EUR' } } })
              close()
            }}
          >
            <div className="flex items-center">
              <EurFlagIcon></EurFlagIcon>
              <span className="ml2">EUR ex. vat</span>
            </div>
            {userCurrency === 'EUR' && <CheckMarkIcon />}
          </button>
          <div class="w-100 bb b--light-gray"></div>
          <button
            className={`w-100  pointer b--none flex items-center pv2 justify-between f5 geo-semibold ${
              userCurrency === 'NOK' ? 'bg-near-white' : 'bg-white'
            }`}
            onClick={() => {
              handleSelect({ currentTarget: { dataset: { name: 'currency', value: 'NOK' } } })
              close()
            }}
          >
            <div className="flex items-center">
              <NokFlagIcon></NokFlagIcon>
              <span className="ml2">NOK inc. vat</span>
            </div>
            {userCurrency === 'NOK' && <CheckMarkIcon />}
          </button>
        </div>
      </Dialog>
    </div>
  )
}

export default CurrencySelector
