import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Button, Intent, Dialog, EditableText } from '@blueprintjs/core'
import Helmet from 'react-helmet'
import styled from 'styled-components'
// Redux store
import { fetchOptions } from '../../store/Options/actions'
import { getOptions } from '../../store/Options/selectors'
import { loadOrder, syncOrder, updateOrder } from '../../store/Orders/actions'
import { getOrder } from '../../store/Orders/selectors'
import { getLoading } from '../../store/Common/selectors'
// Helpers & constants
// import * as status from '../../store/constants'
// Components
import Loading from '../../components/Loading/Loading'
import Section from '../../components/Section/Section'

import BoatImagePoc from '../../components/BoatImage/BoatImagePoc'
import LogoResponsive from '../../components/LogoResponsive'

import Currency from '../../components/Currency/Currency'
import NextLink from '../../components/NextLink/NextLink'

const Navbar = styled.nav`
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
`

const NoticeContainer = styled.div`
  margin: 20px;
`

export class View extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.toggleDialog = this.toggleDialog.bind(this)
    this.routeChange = this.routeChange.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  componentDidMount() {
    this.props.syncOrder(this.props.match.params.id)
    this.props.loadOrder(this.props.match.params.id).then((response) => {
      const val = response.result.val()
      return this.props.fetchOptions(val.boatSlug)
    })
  }

  toggleDialog() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  routeChange = (path) => {
    this.props.history.push(path)
  }

  async handleConfirm() {
    const canvas = document.getElementsByTagName('canvas')[0]
    const link = document.createElement('a')

    link.setAttribute('download', `build-${this.props.order.id}.png`) // Set filename
    link.setAttribute(
      'href',
      canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    )

    const base64 = link.href

    const configImageTitle = `Goldfish Config Image ${this.props.order.id}`
    // send image // Staging
    await fetch('https://my.goldfishboat.com/wp-json/codehouse/v1/uploadImage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ title: configImageTitle, base64_img: base64 }),
    })
      .then(async (data) => await data.json())
      .then(() => this.routeChange(`${this.props.match.url}order-confirmation`))
  }

  render() {
    const { match, order, isFetching, isOptionsFetching, options, commonError } = this.props

    const boat = options.boats ? options.boats.entities[order.boat] : false
    const drivetrain = options.drivetrains ? options.drivetrains.entities[order.drivetrain] : false
    const seat = options.seats ? options.seats.entities[order.seat] : false
    const top = options.tops ? options.tops.entities[order.top] : false
    const customLineItems = order ? order.customLineItems : false
    const equipmentLineItems = order ? order.equipmentLineItems : false
    const addonLineItems = order ? order.addonLineItems : false
    const specialOffersLineItems = order ? order.specialOffersLineItems : false
    let colors = false
    // Loop through colors set in the order
    if (order && options && order.colors && options.colorareas) {
      colors = Object.keys(order.colors).map((e) => {
        const colorArea = options.colorareas.entities[e]

        const colorList = colorArea.acf.colors
        const colorItem = colorList.filter((item) => {
          return item.color.term_id === parseInt(order.colors[e], 10)
        })[0]
        if (colorItem) {
          // TODO: Find out why is color empty sometimes
          return { colorArea, colorItem }
        }
        return false
      })
    }

    let orderTerms = order?.terms ? order.terms : ''

    const termsText = orderTerms
      .replace(/\s/g, ' ')
      .replace('            ', '\n\n')
      .replace('     ', '\n\n')
      .replace('           ', '\n\n')
      .trim()

    let totalCost = 0
    if (drivetrain && top && seat) {
      totalCost += Number(drivetrain.acf.price) + Number(top.acf.price) + Number(seat.acf.price)
    }

    const style = {
      textAlign: 'right',
    }

    if (commonError) {
      return (
        <div>
          <Navbar className="ph3 ph4-ns flex items-center justify-between">
            <LogoResponsive></LogoResponsive>
            {/* <div className="mw5">
              <NextLink to="http://helpdesk.goldfishboat.com/" target="_blank">
                Helpdesk
              </NextLink>
            </div> */}
          </Navbar>
          <Section>
            <Helmet title="Error..." />
            <div className="mw8 center ph3 flex flex-column items-center">
              <p className="tc geo-semibold">{commonError}</p>
            </div>
          </Section>
        </div>
      )
    }

    if (
      isOptionsFetching ||
      isFetching ||
      !options.boats ||
      !options.seats ||
      !options.drivetrains ||
      !options.tops
    ) {
      return (
        <div>
          <Navbar className="ph3 ph4-ns flex items-center justify-between">
            <LogoResponsive></LogoResponsive>
            {/* <div className="mw5">
              <NextLink to="http://helpdesk.goldfishboat.com/" target="_blank">
                Helpdesk
              </NextLink>
            </div> */}
          </Navbar>
          <Section>
            <Helmet title="Loading..." />
            <div className="mw8 center ph3 flex flex-column items-center">
              <h1 className="tc pt3 geo-semibold bp3-skeleton">Goldfish 38 Supersport :: No 001</h1>
              <p className="tc geo-semibold bp3-skeleton">Estimated Completion May 2017</p>
            </div>
            <div className="vh-50 mv4">
              <Loading />
            </div>
            <table className="bp3-html-table bp3-html-table-striped w-100">
              <thead>
                <tr>
                  <th>Your specification</th>
                  <th>Description</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="bp3-skeleton">Boat</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">38 Supersport with Mercury Diesel 260BHP</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">kr 1 730 000</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="bp3-skeleton">Seats</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">Comfort</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">kr 88 000</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="bp3-skeleton">Top</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">No top</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">Included</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="bp3-skeleton">Gelcoat deck</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">Pure White</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">Included</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="bp3-skeleton">Flooring</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">Natural Teak</span>
                  </td>
                  <td>
                    <span className="bp3-skeleton">kr 52 000</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Section>
        </div>
      )
    }

    return (
      <div>
        <Dialog
          icon="tick"
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog}
          title="Confirm Order"
        >
          <div className="bp3-dialog-body">
            Thank You for confirming the reservation of build No.{' '}
            {order && order.buildNumber && order.buildNumber}
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Ok!" intent={Intent.SUCCESS} onClick={this.toggleDialog} />
            </div>
          </div>
        </Dialog>
        <Navbar className="ph3 ph4-ns flex items-center justify-between dn-print">
          <LogoResponsive></LogoResponsive>
          {/* <div className="mw5 ">
            <NextLink to="http://helpdesk.goldfishboat.com/" target="_blank">
              Help & Support
            </NextLink>
          </div> */}
        </Navbar>
        <Section>
          <Helmet title={`View '${match.params.id}'`} />
          {order && order.boat && (
            <h1 className="tc pt3 geo-semibold lh-title">
              Goldfish {options.boats.entities[order.boat].title.rendered}{' '}
              {order && order.buildNumber && `:: No ${order.buildNumber}`}
            </h1>
          )}
          {order && order.estimatedCompletion && (
            <p className="tc f4 black-70 geo-semibold">
              Estimated Completion {order.estimatedCompletion}
            </p>
          )}

          <div style={{ height: '600px' }}>
            {order && order.seat && order.top && (
              <BoatImagePoc
                options={options}
                build={order}
                boat={boat}
                visibleScroller={this.state.visibleScroller}
              />
            )}
          </div>

          <table className="bp3-html-table bp3-html-table-striped w-100">
            <thead>
              <tr>
                <th>Your specification</th>
                <th>Description</th>
                <th style={style}>Price</th>
              </tr>
            </thead>
            <tbody>
              {boat && drivetrain && (
                <tr>
                  <td>Boat</td>
                  <td>
                    {boat.title.rendered} with {drivetrain.acf.display_name}
                  </td>
                  <td style={style}>
                    <Currency to={order.currency}>{drivetrain.acf.price}</Currency>
                  </td>
                </tr>
              )}

              {seat && (
                <tr>
                  <td>Seats</td>
                  <td>{seat.acf.display_name}</td>
                  <td style={style}>
                    <Currency to={order.currency}>{seat.acf.price}</Currency>
                  </td>
                </tr>
              )}
              {top && (
                <tr>
                  <td>Top</td>
                  <td>{top.acf.display_name}</td>
                  <td style={style}>
                    <Currency to={order.currency}>{top.acf.price}</Currency>
                  </td>
                </tr>
              )}
              {colors &&
                colors.map((color) => {
                  if (!color) return null
                  totalCost += Number(color.colorItem.price)
                  const title = color.colorArea.acf.display_name
                  const colorName = color.colorItem.color.name
                  const colorSlug = color.colorItem.color.slug
                  const price = color.colorItem.price
                  return (
                    <tr key={color.colorArea.id}>
                      <td>
                        <div className={`w1 h1  fl br4 mr2 colortag ${colorSlug} dn-print`} />
                        {title}
                      </td>
                      <td>{colorName}</td>
                      <td style={style}>
                        <Currency to={order.currency}>{price}</Currency>
                      </td>
                    </tr>
                  )
                })}
              {equipmentLineItems &&
                equipmentLineItems.map((item, index) => {
                  if (item) {
                    if (!options.equipments.entities[item.equipment]) {
                      return null
                    }
                    totalCost += Number(options.equipments.entities[item.equipment].acf.price)
                    return (
                      <tr key={index}>
                        <td>Equipment</td>
                        <td>{options.equipments.entities[item.equipment].acf.display_name}</td>
                        <td style={style}>
                          <Currency to={order.currency}>
                            {options.equipments.entities[item.equipment].acf.price}
                          </Currency>
                        </td>
                      </tr>
                    )
                  }
                  return null
                })}
              {addonLineItems &&
                addonLineItems.map((item, index) => {
                  if (item) {
                    if (!options.addons.entities[item.addon]) {
                      return null
                    }
                    totalCost += Number(options.addons.entities[item.addon].acf.price)
                    return (
                      <tr key={index}>
                        <td>Addon</td>
                        <td>{options.addons.entities[item.addon].acf.display_name}</td>
                        <td style={style}>
                          <Currency to={order.currency}>
                            {options.addons.entities[item.addon].acf.price}
                          </Currency>
                        </td>
                      </tr>
                    )
                  }
                  return null
                })}
              {customLineItems &&
                customLineItems.map((item, index) => {
                  if (item) {
                    totalCost += Number(item.cost)
                    return (
                      <tr key={index}>
                        <td>Custom</td>
                        <td>{item.description}</td>
                        <td style={style}>
                          <Currency to={order.currency}>{item.cost}</Currency>
                        </td>
                      </tr>
                    )
                  }
                  return null
                })}
            </tbody>
          </table>

          <table className="bp3-html-table w-100 mb4 bp3-html-table-striped">
            <thead>
              <tr>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="geo-semibold b f4">
                  {order.priceSummaryText ? order.priceSummaryText : 'Net cost:'}
                </td>
                <td className="geo-semibold b f4" style={style}>
                  <Currency to={order.currency}>{totalCost}</Currency>
                </td>
              </tr>
              {specialOffersLineItems &&
                specialOffersLineItems.map((item, index) => {
                  if (item) {
                    totalCost += Number(item.cost)
                    return (
                      <tr key={index}>
                        <td>{item.description}</td>
                        <td style={style}>
                          <Currency to={order.currency}>{item.cost}</Currency>
                        </td>
                      </tr>
                    )
                  }
                  return null
                })}
              {specialOffersLineItems && (
                <tr>
                  <td className="geo-semibold b f4">
                    {order.totalCostSummaryText ? order.totalCostSummaryText : 'Total cost:'}
                  </td>
                  <td className="geo-semibold b f4" style={style}>
                    <Currency to={order.currency}>{totalCost}</Currency>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="bp3-callout bp3-intent-primary">
            {order && order.status !== 'Deposit Paid' && (
              <>
                <h5>Terms & Conditions</h5>
                <div className="pa3">
                  <EditableText multiline disabled value={order && termsText} />
                </div>
                <div className="mt3">
                  {order && order.status === 'Offer' && (
                    <Button
                      intent={Intent.SUCCESS}
                      className="geo-semibold t2 bp3-large bp3-icon-tick b"
                      onClick={() => this.routeChange(`${match.url}order-confirmation`)}
                    >
                      Confirm Order
                    </Button>
                  )}
                  {order && order.status === 'Pending Payment' && (
                    <Button
                      intent={Intent.SUCCESS}
                      className="geo-semibold t2 bp3-large bp3-icon-tick b"
                      onClick={() => this.routeChange(`${match.url}order-confirmation`)}
                    >
                      Order confirmed
                    </Button>
                  )}
                  {order && order.status === 'Request' && (
                    <Button
                      disabled
                      intent={Intent.DANGER}
                      className="geo-semibold t2 bp3-large bp3-icon-tick b"
                    >
                      Confirm Order
                    </Button>
                  )}
                </div>
              </>
            )}
            {order && order.status === 'Deposit Paid' && (
              <NoticeContainer>
                <p>
                  Congratulations! Your build slot is now secured. The deposit for the boat
                  reservation has been received and confirmed. A confirmation email has been sent to
                  your email.
                </p>
              </NoticeContainer>
            )}
          </div>
        </Section>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  options: getOptions(state),
  order: getOrder(state, ownProps.match.params.id),
  loading: getLoading(state),
  isFetching: state.orders._isFetching,
  isOptionsFetching: state.options._isFetching,
  commonError: state.common.error,
})

export default connect(mapStateToProps, {
  loadOrder,
  fetchOptions,
  syncOrder,
  updateOrder,
})(View)
