import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="16" height="12" viewBox="0 0 16 12">
      <path
        fill="#000"
        d="M15.66 1.262L4.922 12 0 7.078l1.262-1.261 3.66 3.65L14.398 0l1.262 1.262z"
      ></path>
    </svg>
  )
}

export default Icon
