import React from 'react'
import { Dialog } from '@reach/dialog'
import CloseIcon from '../Icons/CloseIcon'

import SimpleButton from '../../components/SimpleButton/SimpleButton'

const ShareModal = (props) => {
  // const {
  //   drivetrains,
  //   tops,
  //   seats,
  //   colorareas,
  //   equipments,
  //   build,
  //   userCurrency,
  //   handleSelect,
  // } = props

  const [showDialog, setShowDialog] = React.useState(false)
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  const [wasCopied, setWasCopied] = React.useState(false)

  const url = window.location.href + '?locked'
  const handleSubmit = (evt) => {
    /* Get the text field */
    var copyText = document.getElementById('boaturl')

    /* Select the text field */
    copyText.select()
    copyText.setSelectionRange(0, 99999) /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand('copy')
    setWasCopied(true)
  }

  return (
    <div>
      <button
        className="w-100 lh-solid pv3 bg-white db bw0 pointer hover-bg-near-white"
        onClick={open}
      >
        Share your build
      </button>
      <Dialog
        className="pa3"
        style={{ width: '360px', zIndex: 9999 }}
        isOpen={showDialog}
        onDismiss={close}
        aria-label="Share boat build"
      >
        <div className="relative">
          <h3 className="geo-semibold f4 mt0 mb3">Share boat</h3>

          <button
            className="input-reset pointer absolute top-0 right-0 b--none nt3 nr3 pa0 bg-transparent"
            style={{ width: '20px', height: '20px' }}
            onClick={close}
          >
            <CloseIcon></CloseIcon>
          </button>

          <p>Copy this link to share your build with others or save for yourself.</p>
          <textarea
            rows="3"
            id="boaturl"
            value={url}
            className="w-100 pa3 mv3 bg-near-white bw0 br2 f5 geo-regular"
          />
          {wasCopied && <p>Copied to your clipboard!</p>}
          <SimpleButton onClick={handleSubmit}>Copy link</SimpleButton>
        </div>
      </Dialog>
    </div>
  )
}

export default ShareModal
