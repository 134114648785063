import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// Redux store
import { getUserCurrency } from '../../store/Common/selectors'
import { getBuildById } from '../../store/Builds/selectors'
import { config } from '../../config'
import { withRouter } from 'react-router-dom'

class Currency extends Component {
  render() {
    const {
      children,
      locale = 'no',
      to = 'EUR',
      showVat = false,
      // eslint-disable-next-line
      dispatch,
      build,
      ...rest
    } = this.props

    if (children === '0' || children === 0) {
      return (
        <span className="geo-semibold tr" {...rest}>
          Included
        </span>
      )
    }

    let vatText = 'inc. VAT.'
    let convertedPrice
    if (to === 'USD') {
      convertedPrice = config.exchangeRates.USD * Number(children)
      vatText = 'ex. vat'
    } else if (to === 'EUR') {
      convertedPrice = config.exchangeRates.EUR * Number(children)
      vatText = 'ex. VAT'
    } else {
      convertedPrice = Number(children)
    }

    if (children) {
      return (
        <span className="geo-semibold tr" {...rest}>
          {new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: to,
            // maximumSignificantDigits: 4,
            // minimumSignificantDigits: 3,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(convertedPrice)}{' '}
          <span className="geoMedium p-3">{showVat && vatText}</span>
        </span>
      )
    }
    return (
      <span className="geo-semibold tr o-20" {...rest}>
        Price not set
      </span>
    )
  }
}

Currency.propTypes = {
  children: PropTypes.node,
  locale: PropTypes.string,
  to: PropTypes.string,
}

const mapStateToProps = (state, props) => ({
  to: getUserCurrency(state),
  build: getBuildById(state, props.match.params.id),
})

export default withRouter(connect(mapStateToProps)(Currency))
