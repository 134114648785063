import { createActions } from 'redux-actions'
import axios from 'axios'
import qs from 'qs'
import firebase from 'firebase/app'
import 'firebase/database'
import Moment from 'moment'
import { config } from '../../config'

const deleteUndefinedValues = (values) => {
  Object.keys(values).forEach((key) => {
    if (values[key] === undefined) {
      delete values[key]
    }
  })
}

export const {
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  saveOrderRequest,
  saveOrderSuccess,
  saveOrderFailure,
  fetchOrderRequest,
  fetchOrderSuccess,
  fetchOrderFailure,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
  updateOrderRequest,
  updateOrderSuccess,
  updateOrderFailure,
  syncOrderSuccess,
  syncOrdersSuccess,
  postToWordpressRequest,
  postToWordpressSuccess,
  postToWordpressFailure,
} = createActions(
  'CREATE_ORDER_REQUEST',
  'CREATE_ORDER_SUCCESS',
  'CREATE_ORDER_FAILURE',
  'SAVE_ORDER_REQUEST',
  'SAVE_ORDER_SUCCESS',
  'SAVE_ORDER_FAILURE',
  'FETCH_ORDER_REQUEST',
  'FETCH_ORDER_SUCCESS',
  'FETCH_ORDER_FAILURE',
  'FETCH_USERS_REQUEST',
  'FETCH_USERS_SUCCESS',
  'FETCH_USERS_FAILURE',
  'FETCH_ORDERS_REQUEST',
  'FETCH_ORDERS_SUCCESS',
  'FETCH_ORDERS_FAILURE',
  'UPDATE_ORDER_REQUEST',
  'UPDATE_ORDER_SUCCESS',
  'UPDATE_ORDER_FAILURE',
  'SYNC_ORDER_SUCCESS',
  'SYNC_ORDERS_SUCCESS',
  'POST_TO_WORDPRESS_REQUEST',
  'POST_TO_WORDPRESS_SUCCESS',
  'POST_TO_WORDPRESS_FAILURE'
)

// Prepare data for posting to Wordpress
export const sendToMyGoldfish = (data) => (dispatch, getState) => {
  let params = {
    title: `${getState().options.boats.entities[data.boat].title.rendered} ${data.buildNumber}`,
    'fields[build_number]': data.buildNumber,
    'fields[order_date]': Moment().format('YMMDD'),
    'fields[drivetrain_name]':
      getState().options.drivetrains.entities[data.drivetrain].acf.display_name,
    'fields[drivetrain_intro]': getState().options.drivetrains.entities[data.drivetrain].acf.intro,
    'fields[drivetrain_image]':
      getState().options.drivetrains.entities[data.drivetrain].acf.image?.url,
    'fields[specification][0][name]': 'Seat',
    'fields[specification][0][value]':
      getState().options.seats.entities[data.seat].acf.display_name,
    'fields[specification][1][name]': 'Top',
    'fields[specification][1][value]': getState().options.tops.entities[data.top].acf.display_name,
  }
  getState().options.drivetrains.entities[data.drivetrain].acf.meta.map((item, index) => {
    return (params = {
      ...params,
      [`fields[drivetrain_table][${index}][name]`]: item.title,
      [`fields[drivetrain_table][${index}][value]`]: item.value,
    })
  })
  getState().options.boats.entities[data.boat].acf.standard_equipment.map((item, index) => {
    return (params = {
      ...params,
      [`fields[standard_equipment][${index}][value]`]: item.title,
    })
  })
  data.equipmentLineItems &&
    data.equipmentLineItems.map((item, index) => {
      return (params = {
        ...params,
        [`fields[options][${index}][name]`]:
          getState().options.equipments.entities[item.equipment].acf.display_name,
        [`fields[options][${index}][text]`]:
          getState().options.equipments.entities[item.equipment].acf.text,
        [`fields[options][${index}][price]`]:
          getState().options.equipments.entities[item.equipment].acf.price,
        [`fields[options][${index}][image]`]:
          getState().options.equipments.entities[item.equipment].acf.image.url,
      })
    })
  Object.keys(data.colors).map((key, index) => {
    const color = getState().options.colorareas.entities[key].acf.colors.find(
      (color) => color.color.term_taxonomy_id === Number(data.colors[key])
    )
    return (params = {
      ...params,
      [`fields[colors][${index}][category_name]`]:
        getState().options.colorareas.entities[key].acf.display_name,
      [`fields[colors][${index}][color_name]`]: color.color.name,
      [`fields[colors][${index}][color_slug]`]: color.color.slug,
    })
  })
  return dispatch(postToWordpress(params))
}

// Post to Wordpress
const postToWordpress = (params) => {
  return {
    types: [
      postToWordpressRequest().type,
      postToWordpressSuccess().type,
      postToWordpressFailure().type,
    ],
    promise: axios({
      method: 'post',
      url: `${config.wordpress.baseUrl}/builds`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      crossDomain: true,
      data: qs.stringify(params),
      auth: {
        username: 'apiuser',
        password: '2pT4^ABM8o@Lis7csd&#iHCs',
      },
    }),
  }
}

// Fetch all orders from Firebase
const fetchOrders = () => {
  return {
    types: [fetchOrdersRequest().type, fetchOrdersSuccess().type, fetchOrdersFailure().type],
    promise: firebase.database().ref('orders').once('value'),
  }
}

// Fetch all orders from one user from Firebase
const fetchOrdersByUser = (uid) => {
  return {
    types: [fetchOrdersRequest().type, fetchOrdersSuccess().type, fetchOrdersFailure().type],
    promise: firebase.database().ref('orders').orderByChild('agent').equalTo(uid).once('value'),
  }
}

// Load all orders and fetch it from Firebase based on user role
export const loadAllOrders = () => (dispatch, getState) => {
  const uid = getState().user.user.uid
  if (getState().users.entities[uid] && getState().users.entities[uid].role === 'Agent') {
    return dispatch(fetchOrdersByUser(uid))
  }
  return dispatch(fetchOrders())
}

// Fetch one order by id from Firebase
const fetchOrder = (id) => {
  return {
    types: [fetchOrderRequest().type, fetchOrderSuccess().type, fetchOrderFailure().type],
    promise: firebase.database().ref(`/orders/${id}`).once('value'),
  }
}

// Load one order by id and fetch it from Firebase unless it is cached in Redux store
export const loadOrder = (id) => (dispatch, getState) => {
  const orders = getState().orders
  if (orders[id]) {
    return Promise.resolve() // For the .then() in Edit mount call
  }
  return dispatch(fetchOrder(id))
}

// Sync order with Firebase for Realtime Goodness™
export const syncOrder = (id) => (dispatch, getState) => {
  return firebase
    .database()
    .ref(`/orders/${id}`)
    .on('value', (snap) => {
      dispatch(syncOrderSuccess(snap.val()))
    })
}
// Sync all orders with Firebase for Realtime Goodness™
export const syncOrders = (id) => (dispatch, getState) => {
  const uid = getState().user.user.uid
  if (getState().users.entities[uid] && getState().users.entities[uid].role === 'Agent') {
    return dispatch(fetchOrdersByUser(uid))
  }
  return firebase
    .database()
    .ref(`/orders/`)
    .on('value', (snap) => {
      dispatch(syncOrdersSuccess(snap.val()))
    })
}

// Delete node in Firebase
export const deleteOrder = (id) => (dispatch, getState) => {
  return firebase.database().ref(`/orders/${id}`).remove()
}

// Creates a new order in Firebase
export const createOrder = (id, values = {}) => {
  const updates = {
    [`/orders/${id}`]: {
      id,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      status: 'Request',
      terms: config.content.defaultTerms,
      sendEmailConfirmation: false,
      ...values,
    },
  }
  return {
    types: [createOrderRequest().type, createOrderSuccess().type, createOrderFailure().type],
    promise: firebase.database().ref().update(updates),
  }
}

// Saves an order to Firebase
export const saveOrder = (values, id) => {
  deleteUndefinedValues(values)

  const updates = {
    [`/orders/${id}`]: { ...values, sendEmailConfirmation: false, updatedAt: Date.now() },
  }
  return {
    types: [saveOrderRequest().type, saveOrderSuccess().type, saveOrderFailure().type],
    promise: firebase.database().ref().update(updates),
  }
}

// Updates an order to Firebase
export const updateOrder = (values, id) => {
  deleteUndefinedValues(values)
  let updates = {
    [`/orders/${id}/updatedAt`]: Date.now(),
  }
  // eslint-disable-next-line
  Object.keys(values).map((key, index) => {
    const value = values[key]
    updates[`/orders/${id}/${key}`] = value
  })

  return {
    types: [updateOrderRequest().type, updateOrderSuccess().type, updateOrderFailure().type],
    promise: firebase.database().ref().update(updates),
  }
}
