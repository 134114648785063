import React from 'react'
import styled from 'styled-components'
import { lightenDarkenColor } from '../../helpers/colorTransforms'

const Wrapper = styled.button`
  border: 0;
  background-color: #fb5515;
  &:hover {
    background-color: ${(props) => lightenDarkenColor('#fb5515', 20)};
  }
  &:focus {
    box-shadow: 0 0 0 3px gold;
    outline: 3px solid transparent;
  }
`

const SimpleButton = ({ children, disabled, ...rest }) => (
  <Wrapper
    className={`geo-semibold f5 white pv2 pointer link input-reset w-100 ph1 ph2-ns ttu`}
    {...rest}
  >
    {children}
    {/* <img src={require('../Summary/arrow-3.svg').default} alt="Arrow Right" width="14" className="ml1"/>  */}

    <span className="di bp3-icon-standard bp3-icon-arrow-right ml1" style={{ fontSize: '14px' }} />
  </Wrapper>
)

export default SimpleButton
