import { handleActions, combineActions } from 'redux-actions'
import { getBrowserLanguage } from '../../helpers/getBrowserLanguage'
import { syncBuildSuccess } from '../Builds/actions'
import {
  setLoading,
  setError,
  fetchEstimatedDeliveryTimeSuccess,
  fetchEstimatedDeliveryTimeFailure,
  fetchRequestBuildFaqsSuccess,
  fetchCommonOptionsSuccess,
} from './actions'
import {
  syncOrderSuccess,
  fetchOrderFailure,
  fetchOrdersFailure,
  fetchOrderSuccess,
  fetchOrdersSuccess,
} from '../Orders/actions'

const INITIAL_STATE = {
  loading: true,
  saving: false,
  error: false,
  userCurrency: getBrowserLanguage(),
  estimatedDeliveryTime: 90,
}

export const common = handleActions(
  {
    [setLoading]: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      }
    },
    [setError]: (state, action) => {
      return {
        ...state,
        error: action || 'Something went wrong.',
      }
    },
    [fetchEstimatedDeliveryTimeSuccess]: (state, action) => {
      return {
        ...state,
        estimatedDeliveryTime: Number(action.result.data.acf.days),
      }
    },
    [fetchRequestBuildFaqsSuccess]: (state, action) => {
      return {
        ...state,
        requestBuildFaqs: action.result.data.acf.faqs,
      }
    },
    [fetchCommonOptionsSuccess]: (state, action) => {
      return {
        ...state,
        options: action.result.data.acf,
      }
    },
    [combineActions(syncBuildSuccess, syncOrderSuccess)](state, action) {
      return {
        ...state,
        userCurrency: action.payload ? action.payload.currency : getBrowserLanguage(),
        error: false,
      }
    },
    [combineActions(fetchOrderFailure, fetchOrdersFailure, fetchEstimatedDeliveryTimeFailure)](
      state,
      action
    ) {
      return {
        ...state,
        error: 'Something went wrong. Please contact Goldfish Boat Support.',
      }
    },
    [combineActions(fetchOrderSuccess, fetchOrdersSuccess)](state, action) {
      return {
        ...state,
        error: false,
      }
    },
  },
  INITIAL_STATE
)
