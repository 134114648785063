import { handleActions, combineActions } from 'redux-actions'
import { normalize, schema } from 'normalizr'
import {
  fetchAllOptionsRequest,
  fetchAllOptionsSuccess,
  fetchAllOptionsFailure,
  fetchOptionRequest,
  fetchOptionSuccess,
  fetchOptionFailure,
} from './actions'

const optionSchema = new schema.Entity('options')
const optionsSchema = [optionSchema]

const INITIAL_STATE = {
  _isFetching: true,
  _error: null,
}

export const options = handleActions(
  {
    [combineActions(fetchAllOptionsRequest, fetchOptionRequest)](state, payload) {
      return { ...state, _isFetching: true }
    },
    [fetchAllOptionsSuccess]: (state, action) => {
      let transformedData = {}
      const arraymergeParam = 'equipments'

      let transformedEquipmentResult = {}

      let filteredEquipments = action.result.filter((arr) => {
        const urlParamArray = arr.config.url.split('/')
        const urlParam = urlParamArray[urlParamArray.length - 1]
        if (urlParam === arraymergeParam) {
          return arr
        }
      })

      filteredEquipments.map((equipment, index) => {
        if (index === 0) {
          transformedEquipmentResult = equipment
        } else {
          transformedEquipmentResult.data.push(...equipment.data)
        }
      })

      const filteredOtherOptions = action.result.filter((arr) => {
        const urlParamArray = arr.config.url.split('/')
        const urlParam = urlParamArray[urlParamArray.length - 1]
        if (urlParam !== arraymergeParam) {
          return arr
        }
      })

      const preperedArray = [...filteredOtherOptions, transformedEquipmentResult]

      // eslint-disable-next-line
      preperedArray.map((option) => {
        if (!option.data[0]) {
          return
        }
        let normalizedOption = normalize(option.data, optionsSchema)
        transformedData = {
          ...transformedData,
          [option.data[0].type || 'colors']: {
            entities: { ...normalizedOption.entities.options },
            result: normalizedOption.result,
          },
        }
      })
      return {
        ...state,
        ...transformedData,
        _isFetching: false,
      }
    },
    [fetchOptionSuccess]: (state, action) => {
      const normalizedOption = normalize(action.result.data, optionsSchema)
      return {
        ...state,
        [action.result.data[0].type]: {
          entities: { ...normalizedOption.entities.options },
          result: normalizedOption.result,
        },
        _isFetching: false,
      }
    },
    [combineActions(fetchAllOptionsFailure, fetchOptionFailure)]: (state, action) => ({
      ...state,
      _isFetching: false,
      _error: action.error.response ? action.error.data.message : action.error,
    }),
  },
  INITIAL_STATE
)
