import React from 'react'

function Icon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 49">
      <path
        fill="#43B245"
        d="M44.1 24.5A19.6 19.6 0 1124.5 4.9c1.862 0 3.675.27 5.39.76l3.846-3.847A24.063 24.063 0 0024.5 0 24.5 24.5 0 1049 24.5h-4.9zm-29.62-4.704l-3.455 3.48L22.05 34.3l24.5-24.5-3.455-3.479L22.05 27.366l-7.57-7.57z"
      ></path>
    </svg>
  )
}

export default Icon
