import React from 'react'

function Icon(props) {
  const col = props.color || '#43B245'
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <circle cx="10" cy="10" r="9" stroke={col} strokeWidth="2"></circle>
      <path
        fill={col}
        stroke={col}
        strokeWidth="0.5"
        d="M15.144 6.98l.133-.139-.133-.138-.805-.841-.145-.151-.144.15-5.907 6.162L5.95 9.739l-.144-.15-.145.15-.805.842-.133.138.133.138 3.142 3.281.145.151.144-.15 6.857-7.16z"
      ></path>
    </svg>
  )
}

export default Icon
