export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language || navigator.userLanguage
  if (
    browserLanguage === 'nb' ||
    browserLanguage === 'no' ||
    browserLanguage === 'nn' ||
    browserLanguage === 'nb-NO'
  ) {
    return 'NOK'
  } else {
    return 'EUR'
  }
}
