import PropTypes from 'prop-types';
import React from 'react';
import Currency from '../../components/Currency/Currency'

const DefinitionListRow = ({ label, value, color, currency, showPrice }) =>
  <dl className="mt0">
    <div className="flex items-center justify-between pt0 pb3 bb b--black-05">
      <dt className="roboto-regular black-70 p-3">
        {label}
      </dt>
      {showPrice &&
        <dd className="b tr">
          {currency &&
            <Currency>
              {value}
            </Currency>}
          {!currency && value}
        </dd>}
    </div>
  </dl>

DefinitionListRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  color: PropTypes.string,
  currency: PropTypes.bool,
  showPrice: PropTypes.bool,
}

DefinitionListRow.defaultProps = {
  showPrice: true,
}

export default DefinitionListRow
