import React from 'react'

function Icon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <circle cx="10" cy="10" r="9" stroke="#43B245" strokeWidth="2"></circle>
      <path
        fill="#43B245"
        stroke="#43B245"
        strokeWidth="0.5"
        d="M5.75 10.308v.25h3.692v3.692h1.116v-3.692h3.692V9.442h-3.692V5.75H9.442v3.692H5.75v.866z"
      ></path>
    </svg>
  )
}

export default Icon
