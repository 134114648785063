import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { FocusStyleManager } from '@blueprintjs/core'
import configureStore from './store/'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '../node_modules/tachyons/css/tachyons.css'
import './index.css'
import firebase from 'firebase/app'
import { startListeningToAuth } from './store/User/actions'
import App from './containers/App/App.js'
import ErrorBoundry from './components/ErrorBoundry/ErrorBoundry'
import { config } from './config'

// Blueprint accessibility config: http://blueprintjs.com/docs/#a11y
FocusStyleManager.onlyShowFocusOnTabs()

const store = configureStore()

firebase.initializeApp(config.firebase)

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundry>
      <App />
    </ErrorBoundry>
  </Provider>,
  document.getElementById('root')
)

setTimeout(function() {
  store.dispatch(startListeningToAuth())
})
