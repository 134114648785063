import axios from 'axios'
import { createActions, createAction } from 'redux-actions'
import { config } from '../../config'

export const setLoading = createAction('SET_LOADING')
export const setError = createAction('SET_ERROR')

export const {
  fetchEstimatedDeliveryTimeRequest,
  fetchEstimatedDeliveryTimeSuccess,
  fetchEstimatedDeliveryTimeFailure,
  fetchRequestBuildFaqsRequest,
  fetchRequestBuildFaqsSuccess,
  fetchRequestBuildFaqsFailure,
  fetchCommonOptionsRequest,
  fetchCommonOptionsSuccess,
  fetchCommonOptionsFailure,
} = createActions(
  'FETCH_ESTIMATED_DELIVERY_TIME_REQUEST',
  'FETCH_ESTIMATED_DELIVERY_TIME_SUCCESS',
  'FETCH_ESTIMATED_DELIVERY_TIME_FAILURE',
  'FETCH_REQUEST_BUILD_FAQS_REQUEST',
  'FETCH_REQUEST_BUILD_FAQS_SUCCESS',
  'FETCH_REQUEST_BUILD_FAQS_FAILURE',
  'FETCH_COMMON_OPTIONS_REQUEST',
  'FETCH_COMMON_OPTIONS_SUCCESS',
  'FETCH_COMMON_OPTIONS_FAILURE'
)

export const getEstimatedDeliveryTime = () => {
  return {
    types: [
      fetchEstimatedDeliveryTimeRequest().type,
      fetchEstimatedDeliveryTimeSuccess().type,
      fetchEstimatedDeliveryTimeFailure().type,
    ],
    promise: axios({
      method: 'get',
      // url: `${config.wordpress.baseUrlAcf}/options`,
      url: config.wordpress.optionsUrl,
      withCredentials: true,
    }),
  }
}

export const getRequestBuildFaqs = () => {
  return {
    types: [
      fetchRequestBuildFaqsRequest().type,
      fetchRequestBuildFaqsSuccess().type,
      fetchRequestBuildFaqsFailure().type,
    ],
    promise: axios({
      method: 'get',
      url: config.wordpress.optionsUrl,
      withCredentials: true,
    }),
  }
}

export const getCommonOptions = () => {
  return {
    types: [
      fetchCommonOptionsRequest().type,
      fetchCommonOptionsSuccess().type,
      fetchCommonOptionsFailure().type,
    ],
    promise: axios({
      method: 'get',
      url: config.wordpress.optionsUrl,
      withCredentials: true,
    }),
  }
}
