import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled(Link)`
  @media (min-width: 30em) {
    max-width: 200px;
  }
  background-color: transparent;
  color: ${(props) => (props.color ? '#869396' : '#869396')};
  max-width: 90px;
  height: 35px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  &:hover {
    opacity: 1;
    color: #869396;
    text-decoration: none;

    .back-image {
      opacity: 0.55;
    }
  }
`

const BackLink = ({ children, to, disabled, direction, ...rest }) => (
  <Wrapper
    disabled={disabled}
    to={to}
    {...rest}
    className="ba-ns border-orange flex flex-auto items-center justify-center w-100-ns ttu tc db geo-semibold f5 black hover-no-underline no-underline ph1 ph2-ns pv1 pointer input-reset"
  >
    <img
      src={require('../BackLink/arrow-2.svg').default}
      alt="Arrow Left"
      width="14"
      className="mr1 back-image"
    />

    {/* <span
      className="di f7 bp3-icon-standard bp3-icon-arrow-left mr1"
      style={{ fontSize: '14px', marginTop: '2px' }}
    /> */}
    {children}
  </Wrapper>
)

export default BackLink
