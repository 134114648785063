import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Motion, spring } from 'react-motion'
import styled from 'styled-components'
import Currency from '../../components/Currency/Currency'
import P from '../../components/P/P'
import presets from '../../helpers/motionPresets'
import PlusIcon from './PlusIcon'
import ProgressRing from './ProgressRing'
import SelectedIcon from './SelectedIcon'

const TinyText = styled.dt`
  font-size: 13.2px;
  color: #caebf1;
  border: 1px solid #caebf1;
`

const Amount = styled.h5`
  color: ${(props) => (props.selected ? '#CAEBF1' : '#869396')};
`
const Card = styled.div`
  width: 280px;
  z-index: ${(props) => (props.selected ? '100' : '0')};
  height: ${(props) => (props.selected ? '348px' : '250px')};
  margin-top: ${(props) => !props.selected && '6rem'};
  transition: all 0.1s ease-in-out;
  white-space: normal;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(to top left, rgb(127,175,185) , rgba(13, 40, 46, 1))'
      : 'linear-gradient(90deg, rgba(37, 61, 67, 1) , rgba(13, 40, 46, 1))'};
`
const Inner = styled.section`
  width: 280px;
`
const Button = styled.button`
  border-radius: 4px;
  border: none;
  width: calc(100% + 16px);
  padding: 8px;
  margin-left: -8px;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: left;
  cursor: pointer;
  margin-bottom: 8px;
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  background: ${(props) => (props.selected ? '#43B245' : '#E9E9E9')};
  &:hover {
    background: ${(props) => (props.selected ? '#43B245' : '#E2E2E2')};
    color: ${(props) => (props.selected ? '#fff' : '#000')};
  }
`

class DrivetrainCard extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.state = {
      visible: false,
      mounted: false,
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 200)
  }

  onChange(isVisible) {
    this.setState({ visible: isVisible })
  }

  render() {
    const { drivetrain, id, selected, showPrice, ...rest } = this.props
    //
    return (
      <Motion
        style={{
          opacity: spring(this.state.mounted ? 1 : 0, presets.opacity),
          y: spring(this.state.mounted ? 0 : 50, presets.stiff),
        }}
      >
        {({ opacity, y }) => (
          <Card
            {...rest}
            style={{
              opacity: `${opacity}`,
              transform: `translate3d(0, ${y}px, 0)`,
            }}
            value={id}
            selected={selected}
            className={`pointer`}
          >
            {/* <CardHeader
              image={drivetrain.acf.logo ? drivetrain.acf.logo.url : logo}
              price={drivetrain.acf.price}
              showPrice={showPrice}
              value={id}
              onClick={handleSelect}
              selected={selected}
            /> */}
            <Inner className="flex justify-between pointer-events-none flex-column flex-row-l items-start-ns h-100">
              {/* <MotorImage
                className="self-center pa3 h-100 dib-l dn img"
                src={drivetrain.acf.image.url || ''}
                alt={drivetrain.acf.display_name || ''}
              /> */}
              {/* <img className='self-center pa3 h-100 dib-l dn w5 img' src={shadow} role='presentation' /> */}
              <section className="flex justify-start w-100-l ph3 flex-column h-100">
                <div className="w-100-l relative" style={{ height: '190px' }}>
                  <h3 className="truncate geo-semibold p-2 mb0 white">
                    {drivetrain.acf.display_name}
                  </h3>
                  <Amount selected={selected} className="f5 geo-semibold lh-title mt0 mb2">
                    <Currency>{drivetrain.acf.price}</Currency>
                  </Amount>
                  <P className="p-3">{drivetrain.acf.intro.slice(0, 140)}</P>
                  {[drivetrain.acf.meta[0]].map((item, index) => (
                    <div key={index} className="white dib absolute bottom-0">
                      <TinyText selected={selected} className="p-3 geo-regular ph2 pv1">
                        {item.title} <span className="geo-semibold">{item.value}</span>
                      </TinyText>
                    </div>
                  ))}
                </div>
                <div className={!selected && 'dn'}>
                  <div className="flex flex-wrap content-start w-100-l pv3">
                    {drivetrain.acf.stats.map((item, index) => (
                      <div className="flex content-center w-50 pr3 p-3" key={index}>
                        <ProgressRing radius={13} stroke={3} progress={Number(item.value)} />
                        <h5 className="f6 geo-semibold white lh-title mt1 mb0">{item.title}</h5>
                        {/* <SpecGraph visible={this.state.visible} value={Number(item.value)} /> */}
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-wrap w-100">
                    {drivetrain.acf.meta[1] &&
                      drivetrain.acf.meta[2] &&
                      [drivetrain.acf.meta[1], drivetrain.acf.meta[2]].map((item, index) => (
                        <dl key={index} className="lh-title w-50-ns w-50 mv2">
                          <dd className="ml0 f5 white geo-semibold b" style={{ color: '#a6dde8' }}>
                            {item.value}
                          </dd>
                          <span className="p-4 white roboto-regular">{item.title}</span>
                        </dl>
                      ))}
                  </div>
                  {/* <Button selected={selected} className="f5 geo-semibold">
                    {selected ? (
                      <SelectedIcon className="mr2" color="#fff" />
                    ) : (
                      <PlusIcon className="mr2" />
                    )}
                    {selected ? 'Selected' : 'Add'}
                  </Button> */}
                </div>
              </section>
            </Inner>
          </Card>
        )}
      </Motion>
    )
  }
}

DrivetrainCard.propTypes = {
  handleSelect: PropTypes.func,
  drivetrain: PropTypes.object,
  id: PropTypes.number,
  selected: PropTypes.bool,
}

export default DrivetrainCard
