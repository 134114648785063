/**
 * Public configuration
 *
 * Note that the content of this file will be bundled and exposed
 * to all clients -- do not keep sensitive server credentials here.
 *
 * Use the environment variables if available, but fall back to
 * the production values.
 *
 */
export const config = {
  /**
   * WordPress backend
   */
  wordpress: {
    baseUrl:
      process.env.REACT_APP_WORDPRESS_BASE_URL ?? 'https://wp.goldfishboat.com/wp-json/wp/v2',
    baseUrlAcf:
      process.env.REACT_APP_WORDPRESS_BASE_URL_ACF ?? 'https://wp.goldfishboat.com/wp-json/acf/v2',
    optionsUrl:
      process.env.REACT_APP_WORDPRESS_OPTIONS ??
      'https://wp.goldfishboat.com/wp-json/acf/v3/options/options',
  },

  /**
   * Firebase configuration
   */
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? 'AIzaSyCQorkuNvZwuPs7ax42smCLYzji2Ynoh18',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? 'goldfish-config-dev.firebaseapp.com',
    databaseURL:
      process.env.REACT_APP_FIREBASE_DATABASE_URL ?? 'https://goldfish-config.firebaseio.com',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? 'goldfish-config.appspot.com',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? '707696553560',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'goldfish-config',
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? undefined,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? undefined,
  },

  /**
   * Stripe
   */
  stripe: {
    publishableKey:
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? 'pk_live_arHMqSeqRK1BDCY0K6m9RBOa00kYaACELp',
  },

  /**
   * Sentry configuration, for error reporting
   */
  sentry: {
    dsn:
      process.env.REACT_APP_SENTRY_DSN ??
      'https://c13f488308014e2f96fa7d446ca9e257@sentry.io/147515',
    release: process.env.REACT_APP_SENTRY_RELEASE ?? 'goldfish-builder@3.0.0',
    environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
  },

  /**
   * USD/EUR to NOK exchange rates used by the order process
   */
  exchangeRates: {
    USD: 0.1025641026,
    EUR: 0.084,
  },

  /**
   * Content
   */
  content: {
    defaultTerms: `
      Terms of Payment: A 10% deposit will be invoiced at time of order, sum balance fall due at time of completion
    
      Terms of Delivery: The boat is delivered FCA Goldfish Factory.
    
      To confirm this offer and reserve your build number, please click the “Confirm Order” button below. You will be directed to accept the full Terms and Conditions of the purchase, and then to pay an initial deposit.
    `,
  },
}
