import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import DragHandle from '../../components/DragHandle/DragHandle'
import { Button } from '@blueprintjs/core'
import Input from '../../components/Input/Input'
import InputGroup from '../../components/InputGroup/InputGroup'
import * as validator from '../../helpers/validators.js'
import { config } from '../../config';

const SortableItem = SortableElement(({toNOK, toUserCurrency, item, currency, itemIndex, options, handleRemoveItem}) => (
  <li className='flex flex-wrap'>
    <Field
      name={`${item}.description`}
      component={Input}
      label='Description'
      placeholder='Add item description'
      type='text'
      validate={[ validator.required ]}
      classes='flex-auto mr4-ns'
    />
    <Field
      name={`${item}.cost`}
      component={InputGroup}
      label='Cost'
      placeholder='Add item cost'
      type='text'
      // validate={[ validator.required ]}
      classes='w-20 mr3'
      currency={currency}
      parse={toNOK}
      format={toUserCurrency}
    />
    <div className='pt2 flex items-center bp3-button-group bp3-minimal'>
      <Button
        type='button'
        icon='delete'
        data-index={itemIndex}
        onClick={handleRemoveItem}
      />
      <DragHandle />
    </div>
  </li>
  )
)

const SortableList = SortableContainer(({toNOK, toUserCurrency, currency, handleRemoveItem, options, fields}) => (
  <div>
    {fields.map((item, index) =>
      <SortableItem
        key={`item-${index}`}
        handleRemoveItem={handleRemoveItem}
        index={index}
        itemIndex={index}
        item={item}
        options={options}
        currency={currency}
        toNOK={toNOK}
        toUserCurrency={toUserCurrency}
      />
    )}
  </div>
  )
)

class CustomLineItem extends Component {
  constructor (props) {
    super(props)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.handleRemoveItem = this.handleRemoveItem.bind(this)
    this.toUserCurrency = this.toUserCurrency.bind(this)
    this.toNOK = this.toNOK.bind(this)
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.props.fields.move(oldIndex, newIndex)
  }

  handleRemoveItem = (e) => {
    this.props.fields.remove(e.target.dataset.index)
  }

  toUserCurrency = value => {
    if (isNaN(value)) {
      return
    }
    if (this.props.currency === 'USD') {
      return (config.exchangeRates.USD * Number(value)).toFixed()
    } else if (this.props.currency === 'EUR') {
      return (config.exchangeRates.EUR * Number(value)).toFixed()
    } else {
      return Number(value).toFixed()
    }
  }

  toNOK = value => {
    if (this.props.currency === 'USD') {
      return (value / config.exchangeRates.USD)
    } else if (this.props.currency === 'EUR') {
      return (value / config.exchangeRates.EUR)
    } else {
      return value
    }
  }

  render () {
    const {
      options,
      fields,
      currency,
      meta: {
        touched,
        error
      }
    } = this.props

    return (
      <ul className='list pl0'>
        <SortableList
          lockAxis={'y'}
          useDragHandle
          handleRemoveItem={this.handleRemoveItem}
          currency={currency}
          options={options}
          fields={fields}
          onSortEnd={this.onSortEnd}
          toNOK={this.toNOK}
          toUserCurrency={this.toUserCurrency}
        />
        <li className='flex bp3-button-group'>
          <Button icon='add' type='button' onClick={() => fields.push({})}>Add equipment</Button>
          {touched && error && <span>{error}</span>}
        </li>
      </ul>
    )
  }
}

CustomLineItem.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string
  })
}

export default CustomLineItem
