import axios from 'axios'
import { createActions } from 'redux-actions'
import { config } from '../../config'

export const {
  fetchAllOptionsRequest,
  fetchAllOptionsSuccess,
  fetchAllOptionsFailure,
  fetchOptionRequest,
  fetchOptionSuccess,
  fetchOptionFailure,
} = createActions(
  'FETCH_ALL_OPTIONS_REQUEST',
  'FETCH_ALL_OPTIONS_SUCCESS',
  'FETCH_ALL_OPTIONS_FAILURE',
  'FETCH_OPTION_REQUEST',
  'FETCH_OPTION_SUCCESS',
  'FETCH_OPTION_FAILURE'
)

export const fetchOptions = (boatmodel = '') => {
  const optionsToFetch = [
    'boats',
    'drivetrains',
    'colorareas',
    'seats',
    'tops',
    'equipments',
    'color',
    'addons',
  ]

  const promises = optionsToFetch.map((option) => {
    let options = {}
    if (option === 'equipments' || option === 'addons') {
      options._embed = 1
    }

    if (option === 'equipments') {
      let promiseArray = []
      const querySize = 50
      for (let i = 0; i < 10; i++) {
        promiseArray.push(
          axios({
            method: 'get',
            url: `${config.wordpress.baseUrl}/${option}`,
            withCredentials: true,
            params: {
              per_page: querySize,
              'filter[orderby]': 'title',
              'filter[boatmodel]': boatmodel,
              offset: querySize * i,
              order: 'asc',
              ...options,
            },
          })
        )
      }
      return promiseArray.map((promise) => promise)
    } else {
      return axios({
        method: 'get',
        url: `${config.wordpress.baseUrl}/${option}`,
        withCredentials: true,
        params: {
          per_page: 100,
          'filter[orderby]': 'title',
          'filter[boatmodel]': boatmodel,
          order: 'asc',
          ...options,
        },
      })
    }
  })

  return {
    types: [
      fetchAllOptionsRequest().type,
      fetchAllOptionsSuccess().type,
      fetchAllOptionsFailure().type,
    ],
    promise: Promise.all(promises.flat()),
  }
}

export const fetchOptionByType = (type, boatmodel = '') => {
  return {
    types: [fetchOptionRequest().type, fetchOptionSuccess().type, fetchOptionFailure().type],
    promise: axios({
      method: 'get',
      url: `${config.wordpress.baseUrl}/${type}`,
      withCredentials: true,
      params: {
        per_page: 100,
        'filter[boatmodel]': boatmodel,
      },
    }),
  }
}
