import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { options } from './Options/reducers'
import { common } from './Common/reducers'
import { orders } from './Orders/reducers'
import { user } from './User/reducers'
import { selection } from './Selection/reducers'
import { builds } from './Builds/reducers'
import { users } from './Users/reducers'

const rootReducer = combineReducers({
  common,
  options,
  orders,
  user,
  selection,
  builds,
  users,
  form: formReducer
})

export default rootReducer
