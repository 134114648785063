import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from '../middleware/promiseMiddleware'
import rootReducer from './reducers.js'

// eslint-disable-next-line
const composeEnhancers = process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export default function configureStore () {
  const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(thunk, promiseMiddleware)
  )(createStore)

  const store = createStoreWithMiddleware(rootReducer)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers.js', () => {
      const nextRootReducer = require('./reducers.js')
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}
