import { config } from '../config'

const getCurrentTotalCost = (
  drivetrains,
  tops,
  seats,
  colorareas,
  equipments,
  build,
  userCurrency,
  addons
) => {
  let totalCost = 0

  if (drivetrains && build.drivetrain) {
    totalCost += Number(drivetrains.entities[build.drivetrain].acf.price)
  }
  if (tops && build.top && tops.entities[build.top].acf.price) {
    totalCost += Number(tops.entities[build.top].acf.price)
  }
  if (seats && build.seat && seats.entities[build.seat]) {
    totalCost += Number(seats.entities[build.seat].acf.price)
  }
  if (colorareas && build.colors) {
    // eslint-disable-next-line
    Object.keys(build.colors).map((colorKey) => {
      colorareas.entities[colorKey].acf.colors
        .filter((color) => color.color.term_id === Number(build.colors[colorKey]))
        // eslint-disable-next-line
        .map((item) => {
          totalCost += Number(item.price)
        })
    })
  }

  if (equipments && build.equipmentLineItems) {
    // eslint-disable-next-line
    build.equipmentLineItems?.map((equipment) => {
      if (!equipments.entities[equipment.equipment]) {
        return null
      }
      totalCost += Number(equipments.entities[equipment.equipment].acf.price)
    })
  }

  if (addons && build.addonLineItems) {
    // eslint-disable-next-line
    build.addonLineItems?.map((addon) => {
      if (!addons.entities[addon.addon]) {
        return null
      }
      totalCost += Number(addons.entities[addon.addon].acf.price)
    })
  }

  let convertedPrice
  if (userCurrency === 'USD') {
    convertedPrice = config.exchangeRates.USD * Number(totalCost)
  } else if (userCurrency === 'EUR') {
    convertedPrice = config.exchangeRates.EUR * Number(totalCost)
  } else {
    convertedPrice = Number(totalCost)
  }
  return convertedPrice
}

export default getCurrentTotalCost
