import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button, Intent, Icon } from '@blueprintjs/core'

const renderOption = (option, index) => {
  if (option.color) {
    return (
      <option key={index} value={option.color.term_id} data-cost={option.price || 0}>
        {option.color.name}
      </option>
    )
  }
  return (
    <option
      key={index}
      value={option.id ? option.id : option.uid ? option.uid : option}
      data-cost={option.cost || 0}
    >
      {option.displayName
        ? option.displayName
        : option.displayName === ''
        ? `Option id ${option.id}`
        : option}
    </option>
  )
}

const renderDescription = (descriptionArray, setShowHover) => {
  return (
    <div
      className="bp3-card bp3-elevation-1 center z-2 bg-white absolute w-25 center"
      style={{ marginLeft: '1rem' }}
    >
      <div className="flex justify-between">
        <h3 style={{ marginTop: '0px' }}>Description</h3>
        <button
          style={{}}
          className="bg-white ba bw0 f5 lh-title self-start"
          onClick={() => setShowHover(false)}
        >
          Close
        </button>
      </div>
      {descriptionArray.map((descOption, index) => {
        return <p key={index}>{descOption}</p>
      })}
    </div>
  )
}

const SelectWithDesc = ({
  classes,
  input,
  label,
  type,
  options,
  autocomplete,
  description,
  placeholder,
  disabled = false,
  meta: { touched, error, warning },
}) => {
  const [showHover, setShowHover] = useState(false)

  return (
    <div className={classes}>
      <label className="bp3-label" htmlFor={input && input.name}>
        <div className="flex justify-between">
          {label}
          {description && (
            <Icon
              icon="help"
              style={{ color: '#5c7080' }}
              onClick={() => setShowHover(!showHover)}
            />
          )}
        </div>
        {showHover && renderDescription(description, setShowHover)}
        <div className="bp3-select">
          <select
            {...input}
            className={`bp3-fill ${error && touched && 'bp3-intent-danger'}`}
            autoComplete={autocomplete}
            onChange={(e) => {
              input.onChange(e)
            }}
            disabled={disabled}
          >
            <option disabled value="">
              {placeholder}
            </option>
            {options && options.map((option, index) => renderOption(option, index))}
          </select>
          {touched && error && <span className="mt1 db red">{error}</span>}
        </div>
      </label>
    </div>
  )
}

SelectWithDesc.propTypes = {
  classes: PropTypes.string,
  autocomplete: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  description: PropTypes.array,
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
}

export default SelectWithDesc
