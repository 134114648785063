import React, { Component } from 'react'
import Loading from '../../components/Loading/Loading'
import OptionsIcon from './OptionsIcon'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import ShareModal from './ShareModal'

const SdvWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > #sd-viewer {
    position: relative;
    width: 100%;
    height: 100%;
  }
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const OptionsButton = styled.button`
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const OptionsModal = styled(motion.div)`
  overflow: hidden;
  background: #fff;
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`

const getHandheldPosition = (cameraObject) => {
  const Cp = cameraObject.position
  const Ct = cameraObject.target

  // console.log(Cp, Ct)
  let newX = (Cp[0] - Ct[0]) * 1.8 + Ct[0]
  let newY = (Cp[1] - Ct[1]) * 1.8 + Ct[1]
  let newZ = (Cp[2] - Ct[2]) * 1.8 + Ct[2]
  if (Math.abs(newX) < 2700) {
    newX = newX < 0 ? -2700 : 2700
  }
  if (Math.abs(newY) < 2700) {
    newY = newY < 0 ? -2700 : 2700
  }
  if (Math.abs(newZ) < 2700) {
    newZ = newZ < 0 ? -2700 : 2700
  }

  return [newX, newY, newZ]
}

export class SdvViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sdvInitFinished: false,
      parameters: [],
      modalOpen: false,
    }
    this.sdvApi = null

    this.downloadImage = this.downloadImage.bind(this)
  }

  componentDidMount() {
    //
    this.sdvApi = new window.SDVApp.ParametricViewer({
      container: this.sdvContainer,
      modelViewUrl: 'eu-central-1',
      deferGeometryLoading: true,
      ticket: this.props.ticket,
      showFullscreenButton: true,
    })

    // Register an event listener to get notified about the first parameter registration
    const token = this.sdvApi.parameters.addEventListener(
      this.sdvApi.parameters.EVENTTYPE.REGISTERED,
      (e) => {
        this.sdvApi.parameters.removeEventListener(token.data)
        this.sdvApi.updateSettingAsync('scene.camera.restrictions.rotation.maxPolarAngle', 85)
        this.sdvApi.updateSettingAsync('scene.camera.restrictions.zoom.maxDistance', 30000)
        this.sdvApi.updateSettingAsync('scene.camera.restrictions.zoom.minDistance', 2600)

        setTimeout(() => {
          this.setState({ parameters: this.sdvApi.parameters.get().data })
          this.updateModelParams(this.props)
          //
        }, 0)
      }
    )

    this.sdvApi.state.addEventListener(this.sdvApi.state.EVENTTYPE.IDLE, (event) => {
      // this.setState({ parameters: this.sdvApi.parameters.get().data });
      // this.updateModelParams(this.props);
      this.setState({ sdvInitFinished: true })
    })
  }

  shouldComponentUpdate(nextProps, _nextState) {
    const { parameterMap: ignore, ...restOfNextProps } = nextProps
    const { parameterMap: ignoreMeToo, ...restOfProps } = this.props
    const { cameraPositions, customCameraPosition } = nextProps

    if (_nextState.modalOpen !== this.state.modalOpen) {
      return true
    }
    // Check if everything is the same as last time
    const different = Object.keys(restOfNextProps)
      .map((key) => {
        return restOfNextProps[key] !== restOfProps[key]
      })
      .some((i) => i)

    if (!different && _nextState.sdvInitFinished === this.state.sdvInitFinished) {
      return false
    }

    if (customCameraPosition) {
      let cameraObject = customCameraPosition
      if (window.innerWidth < 960) {
        cameraObject.position = getHandheldPosition(cameraObject)
      }
      this.sdvApi.scene.camera.updateAsync(cameraObject)
    } else if (nextProps.visibleScroller) {
      //
      //   'Current wanted position:',
      //   nextProps.visibleScroller,
      //   'exists:',
      //   CameraPositions[nextProps.visibleScroller]
      // )
      let cameraObject = cameraPositions[nextProps.visibleScroller] || cameraPositions.main
      if (window.innerWidth < 960) {
        cameraObject.position = getHandheldPosition(cameraObject)
      }
      setTimeout(() => {
        this.sdvApi.scene.camera.updateAsync(cameraObject)
      }, 0)
    } else {
      let cameraObject = cameraPositions.main || {
        position: { x: -4971.33895337932, y: 8097.514198267563, z: 7519.7680611228825 },
        target: { x: 104.61290652018073, y: 3886.440285755593, z: 3.015489744271154 },
      }
      if (window.innerWidth < 960) {
        cameraObject.position = getHandheldPosition(cameraObject)
      }

      setTimeout(() => {
        this.sdvApi.scene.camera.updateAsync(cameraObject)
      }, 0)
    }

    if (this.state.sdvInitFinished) {
      this.updateModelParams(nextProps, this.props)
    }
    return true
  }

  updateModelParams(props, lastProps) {
    const { parameterMap, equipments, addons, ...rest } = props

    Object.keys(parameterMap).forEach((i) => {
      const parameterName = rest[i]
      const lastParameterName = lastProps ? lastProps[i] : ''
      const parameterMapId = parameterMap[i].parameterId
      // Check if we should update
      if (!lastProps || lastParameterName !== parameterName) {
        //
        if (parameterName) {
          let parameterMapValue = parameterMap[i][parameterName]
          if (typeof parameterMapValue === 'undefined') {
            console.warn(
              'Parameter value can not be associated with model parameters. Setting to default (0). Parameter is:',
              parameterName,
              parameterMapValue
            )
            parameterMapValue = 0
          }

          this.sdvApi.parameters.updateAsync({ id: parameterMapId, value: parameterMapValue })
        } else {
          console.warn('Parameter does not exist in parameter map:', i)
        }
      }
    })

    // Loop thoouh equipments
    Object.keys(equipments).map((key) => {
      return this.sdvApi.parameters.updateAsync({ id: key, value: equipments[key] })
    })

    // Loop through old ones
    if (lastProps) {
      Object.keys(lastProps.equipments).map((key) => {
        if (!equipments[key]) {
          return this.sdvApi.parameters.updateAsync({ id: key, value: 0 })
        }
        return null
      })
    }

    // Loop thoouh addons
    Object.keys(addons).map((key) => {
      return this.sdvApi.parameters.updateAsync({ id: key, value: addons[key] })
    })

    // Loop through old ones
    if (lastProps) {
      Object.keys(lastProps.addons).map((key) => {
        if (!addons[key]) {
          return this.sdvApi.parameters.updateAsync({ id: key, value: 0 })
        }
        return null
      })
    }
  }

  setContainer(el) {
    this.sdvContainer = el
  }

  downloadImage() {
    const canvas = this.sdvContainer.getElementsByTagName('canvas')[0]
    const link = document.createElement('a')
    link.download = 'boat.png'
    link.href = canvas.toDataURL()
    link.click()
  }

  render() {
    const { sdvInitFinished, modalOpen } = this.state
    const { buildId } = this.props
    //
    return (
      <>
        <SdvWrapper>
          {sdvInitFinished ? null : (
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          )}
          <div id={'sd-viewer'} ref={(el) => this.setContainer(el)}></div>
          <OptionsButton
            className="ma3"
            onClick={() => {
              //
              this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }))
            }}
          >
            <OptionsIcon />
          </OptionsButton>
          <AnimatePresence>
            {modalOpen && (
              <OptionsModal
                className="mr3"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
              >
                <button
                  className="bg-white w-100 lh-solid pv3 db bw0 pointer hover-bg-near-white"
                  onClick={() => {
                    this.sdvApi.updateSettingAsync('scene.fullscreen', true)
                  }}
                >
                  Full view
                </button>
                <div className="bb b--light-gray db"></div>
                <button
                  className="bg-white w-100 lh-solid pv3 db bw0 pointer hover-bg-near-white"
                  onClick={() => {
                    console.log(this.sdvApi.scene.camera.get().data)
                  }}
                >
                  Get camera
                </button>
                <div className="bb b--light-gray db"></div>
                <button
                  className="bg-white w-100 lh-solid pv3 db bw0 pointer hover-bg-near-white"
                  onClick={this.downloadImage}
                >
                  Download Image
                </button>
                <div className="bb b--light-gray db"></div>
                <ShareModal></ShareModal>
                {/* <button
              className="bg-white w-100 lh-solid pv3 db bw0 pointer hover-bg-near-white"
              onClick={this.handleFullView}
            >
              Save build
            </button> */}
              </OptionsModal>
            )}
          </AnimatePresence>
        </SdvWrapper>
      </>
    )
  }
}
