export const getOptions = (state) => state.options
export const getBoats = (state) => state.options.boats
export const getDrivetrains = (state) => state.options.drivtrains
export const getColorareas = (state) => state.options.colorareas
export const getColors = (state) => state.options.colors
export const getSeats = (state) => state.options.seats
export const getTops = (state) => state.options.tops
export const getAddons = (state) => state.options.addons
export const getEquipments = (state) => state.options.equipments
export const getOptionsIsFetching = (state) => state.options._isFetching
