import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Faq from '../../components/Faq'
// Components
import QuoteForm from '../../components/QuoteForm/QuoteForm'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount'
// Store
import { getRequestBuildFaqs } from '../../store/Common/actions'
import { getCommonOptions } from '../../store/Common/actions'

const Wrapper = styled.section`
  background: white;
`

class QuoteRequest extends Component {
  componentDidMount() {
    this.props.getRequestBuildFaqs()
    this.props.getCommonOptions()
  }

  render() {
    const { match } = this.props
    const requestType = this.props.requestType

    return (
      <Wrapper className="pb4 pt3 pt3-ns ml-auto mr-auto" style={{ maxWidth: '600px' }}>
        <ScrollToTopOnMount />
        <Helmet title="Send Request" />

        <QuoteForm
          match={match}
          onSubmit={this.props.onSubmit}
          requestType={requestType}
          initialValues={{ requestType }}
          terms={this.props.terms}
        />
        <Faq></Faq>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, props) => ({
  requestBuildFaqs: state.common.requestBuildFaqs,
  terms: state.common.options && state.common.options.terms,
})

export default connect(mapStateToProps, {
  getRequestBuildFaqs,
  getCommonOptions,
})(QuoteRequest)
