/* global dataLayer */
import React, { useEffect } from 'react'
import TopMenu from '../../components/TopMenu'
import SuccessIcon from './SuccessIcon'
import qs from 'qs'
import { connect } from 'react-redux'
import { getCommonOptions } from '../../store/Common/actions'
import styled from 'styled-components'
import { lightenDarkenColor } from '../../helpers/colorTransforms'

const Wrapper = styled.a`
  border: 0;
  background-color: #fb5515;
  width: fit-content;
  padding: 0.6em 1.5em;
  &:hover {
    text-decoration: none;
    color: white;
    background-color: ${(props) => lightenDarkenColor('#fb5515', 20)};
  }
  &:focus {
    box-shadow: 0 0 0 3px gold;
    outline: 3px solid transparent;
  }
`

const SectionWrapper = styled.section`
  height: calc(100vh);
  display: flex;
  align-items: center;
`

const ContentContainer = styled.div`
  @media (min-width: 768px) {
    margin-top: -60px;
  }
`
const PaymentSuccess = ({ location, list, intro, getCommonOptions }) => {
  useEffect(() => {
    const params = qs.parse(location.search)
    dataLayer.push({
      event: 'paymentSuccess',
      eventCategory: 'payment',
      eventAction: params.model,
      eventValue: params.amount / 100, // We get back øre/cents
      currency: params.currency,
    })
  }, [])

  useEffect(() => {
    getCommonOptions()
  }, [])

  // console.log(list, intro)

  return (
    <>
      <TopMenu />
      {/* <div className="ph3 center" style={{ maxWidth: '650px', marginBottom: '4rem' }}> */}
      <SectionWrapper className="mw6 ph3 center flex-wrap align-center self-center">
        <ContentContainer>
          {/* <section className="mw8 ph3 center"> */}
          <h1 className="f2 black geo-semibold mb2 mt5 mb4 tl">Thank you!</h1>
          <p className="geo-regular f5 mb4">{intro}</p>
          {list &&
            list.map((term, index) => (
              <div className="flex align-start mb3 ">
                <div className="w2 mh2">
                  <img src={term.icon}></img>
                </div>
                <div className="w-100">
                  <h4 className="f5 geo-semibold mb2 mt0">{term.headline}</h4>
                  <p className="f5 geo-regular mt0">
                    <div dangerouslySetInnerHTML={{ __html: term.text }} />
                  </p>
                </div>
              </div>
            ))}
          <Wrapper
            className="dib geo-semibold f5 link white pv2 pointer link input-reset w-100 ph1 ph2-ns ttu mt4"
            href="https://goldfishboat.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go back to Goldfishboat.com
          </Wrapper>
          {/* </section> */}
        </ContentContainer>
      </SectionWrapper>
      {/* <h1 className="f3 black geo-semibold mb2 mt5 ">Thank you!</h1>
        <p className="geo-regular f5 mb4">
          Your order is registered. We appreciate the confidence in Goldfish Boat. We look forward
          to undertaking the task of building your next boat!
          <br />
          <br />A Goldfish representative will contact you shortly.
        </p>
        <SuccessIcon width="49" height="49"></SuccessIcon>
        <br /> */}
      {/* </div> */}
    </>
  )
}

const mapStateToProps = (state, props) => ({
  intro: state.common.options && state.common.options.intro,
  list: state.common.options && state.common.options.list,
})

export default connect(mapStateToProps, {
  getCommonOptions,
})(PaymentSuccess)

// export default PaymentSuccess
