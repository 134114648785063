import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21 24"
      width="24px"
      height="21px"
    >
      <path
        fill="#43B245"
        d="M12.6 12.6h1.575v2.961l2.562 1.48-.787 1.365-3.35-1.932V12.6zm4.2-5.25H2.1V18.9h4.903a7.364 7.364 0 01-.703-3.15 7.35 7.35 0 017.35-7.35c1.123 0 2.195.252 3.15.704V7.35zM2.1 21A2.1 2.1 0 010 18.9V4.2c0-1.166.934-2.1 2.1-2.1h1.05V0h2.1v2.1h8.4V0h2.1v2.1h1.05a2.1 2.1 0 012.1 2.1v6.405A7.306 7.306 0 0121 15.75a7.35 7.35 0 01-7.35 7.35A7.306 7.306 0 018.505 21H2.1zm11.55-10.342a5.092 5.092 0 100 10.184 5.092 5.092 0 000-10.184z"
      ></path>
    </svg>
  )
}

export default Icon
