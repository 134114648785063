import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="#82C8D1"></circle>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.2"
        d="M4.927 8.598l-.064.069.064.068 5 5.333.073.078.073-.078 5-5.333.064-.068-.064-.069-.625-.666-.073-.078-.073.078L10 12.52 5.698 7.93l-.073-.077-.073.078-.625.666z"
      ></path>
    </svg>
  )
}

export default Icon
